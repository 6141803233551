/* line 1, /home/coco/codecombat/app/styles/core/loading-error.sass */
#loading-error {
  padding: 20px; }
  /* line 4, /home/coco/codecombat/app/styles/core/loading-error.sass */
  #loading-error .btn {
    margin-top: 20px; }
  /* line 7, /home/coco/codecombat/app/styles/core/loading-error.sass */
  #loading-error .login-btn {
    margin-right: 0px; }
  /* line 10, /home/coco/codecombat/app/styles/core/loading-error.sass */
  #loading-error #create-account-btn {
    margin-left: 10px; }
  /* line 13, /home/coco/codecombat/app/styles/core/loading-error.sass */
  #loading-error #not-found-img {
    max-width: 20%;
    margin: 20px 0; }
  /* line 17, /home/coco/codecombat/app/styles/core/loading-error.sass */
  #loading-error #links-row {
    margin-top: 50px; }
