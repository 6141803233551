/* line 2, /home/coco/codecombat/app/styles/editor/achievement/edit.sass */
#editor-achievement-edit-view .treema-root {
  margin: 28px 0px 20px; }

/* line 5, /home/coco/codecombat/app/styles/editor/achievement/edit.sass */
#editor-achievement-edit-view .achievement-tool-button {
  float: right;
  margin-top: 15px;
  margin-left: 10px; }

/* line 10, /home/coco/codecombat/app/styles/editor/achievement/edit.sass */
#editor-achievement-edit-view textarea {
  width: 92%;
  height: 300px; }

/* line 14, /home/coco/codecombat/app/styles/editor/achievement/edit.sass */
#editor-achievement-edit-view #achievement-view {
  min-height: 200px;
  position: relative;
  padding-left: 200px; }
