/* line 5, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal .modal-dialog {
  margin: 60px auto 0 auto;
  padding: 0;
  width: 746px;
  height: 520px;
  background: none; }

/* line 12, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal li {
  margin-bottom: 16px; }

/* line 16, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal h1 {
  position: absolute;
  left: 155px;
  top: 25px;
  margin: 0;
  width: 410px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0, black 6px 6px 6px;
  font-variant: normal;
  text-transform: uppercase; }

/* line 31, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #image-content {
  position: absolute;
  left: 0%;
  width: 50%;
  height: 100%; }
  /* line 37, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #image-content #pickaxe {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1.25);
    filter: drop-shadow(0 0 16px white); }
  /* line 44, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #image-content #hands {
    width: 150%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -55%;
    position: absolute;
    filter: drop-shadow(0 0 8px black); }

/* line 52, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #close-modal {
  position: absolute;
  left: 568px;
  top: 17px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 65, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #close-modal:hover {
    color: yellow; }

/* line 68, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #info-text, #mine-modal #capacity-text {
  font-size: large; }
  /* line 70, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #info-text div, #mine-modal #capacity-text div {
    margin: 6px; }

/* line 73, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #capacity-text {
  display: none; }
  /* line 76, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #capacity-text #notify-me-form {
    position: absolute;
    bottom: 90px;
    width: 100%; }
    /* line 81, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
    #mine-modal #capacity-text #notify-me-form #notify-me-check {
      cursor: pointer; }

/* line 85, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #button-container {
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center; }
  /* line 91, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #button-container #submit-button {
    display: none;
    width: 256px; }
    /* line 95, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
    #mine-modal #button-container #submit-button .no-text-transform {
      text-transform: none; }
  /* line 98, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #button-container #buy-now-button {
    width: 256px; }

/* line 101, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal .sale-button {
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-success-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2;
  filter: drop-shadow(4px 4px 8px black); }
  /* line 107, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .sale-button:hover {
    border-image: url(/images/common/button-background-success-inactive.png) 14 20 20 20 fill round; }
  /* line 109, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .sale-button:active {
    border-image: url(/images/common/button-background-success-pressed.png) 14 20 20 20 fill round; }
  /* line 115, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .sale-button span {
    pointer-events: none; }

/* line 118, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal .purchase-button {
  flex-grow: 1;
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 127, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .purchase-button span {
    pointer-events: none; }
  /* line 130, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .purchase-button:hover {
    border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
  /* line 133, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal .purchase-button:active {
    border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
    padding: 2px 0 0 2px;
    color: white; }

/* line 138, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #mine-details {
  text-align: center;
  width: 625px;
  height: 325px;
  position: absolute;
  left: 60px;
  top: 140px;
  border-style: solid;
  border-image: url("/images/pages/play/level-info-background.png") 14 20 20 20 fill stretch;
  border-width: 14px 20px 20px 20px;
  display: none; }

/* line 149, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #text-content {
  position: absolute;
  left: 50%;
  width: calc(50% - 16px);
  height: calc(100% - 16px);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.625);
  border-radius: 8px;
  margin: 8px;
  color: white; }
  /* line 160, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
  #mine-modal #text-content #details-header {
    font-size: large;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-align: center; }

/* line 167, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #mine-info {
  position: absolute;
  right: -20px;
  top: 480px;
  width: 256px; }

/* line 173, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #modal-background {
  position: absolute;
  top: -61px;
  left: 0px; }

/* line 178, /home/coco/codecombat/app/styles/modal/mine-modal.sass */
#mine-modal #mine-content {
  width: 685px;
  height: 385px;
  position: absolute;
  left: 30px;
  top: 110px;
  overflow: hidden;
  background-image: url("/images/minecraft/bans.jpg");
  border-radius: 8px;
  background-size: cover;
  background-position: 50% 75%; }
