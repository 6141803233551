/* line 2, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view td {
  cursor: pointer; }

/* line 4, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .task-check {
  width: 5em; }

/* line 6, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .task-check, #editor-level-tasks-tab-view .task-check * {
  margin: auto;
  text-align: center; }

/* line 9, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .edit-cell {
  width: 5em; }

/* line 11, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .edit-cell, #editor-level-tasks-tab-view .edit-cell * {
  margin: auto;
  text-align: center; }

/* line 14, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .task-name {
  margin: auto; }

/* line 16, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .start-edit {
  cursor: pointer; }

/* line 18, /home/coco/codecombat/app/styles/editor/level/tasks-tab.sass */
#editor-level-tasks-tab-view .no-article {
  color: #a00000; }
