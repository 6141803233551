/* line 4, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
#level-flags-view {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  z-index: 1;
  -webkit-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 4px;
  background: transparent url(/images/level/gold_background.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 4px; }
  /* line 19, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
  #level-flags-view:hover {
    box-shadow: 2px 2px 2px black; }
  /* line 22, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
  #level-flags-view .flag-button {
    margin: 3px;
    font-size: 14px;
    position: relative;
    padding: 2px 15px 18px 15px;
    margin-left: 5px; }
    /* line 29, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button .glyphicon {
      font-size: 24px; }
    /* line 32, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button .flag-shortcut {
      text-decoration: underline; }
    /* line 36, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button.green-flag .glyphicon, #level-flags-view .flag-button.green-flag .flag-shortcut {
      color: #40bf40; }
    /* line 39, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button.black-flag .glyphicon, #level-flags-view .flag-button.black-flag .flag-shortcut {
      color: #404040; }
    /* line 42, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button.violet-flag .glyphicon, #level-flags-view .flag-button.violet-flag .flag-shortcut {
      color: #bf40bf; }
    /* line 45, /home/coco/codecombat/app/styles/play/level/level-flags-view.sass */
    #level-flags-view .flag-button .flag-caption {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      color: black;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
