/* line 13, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
#level-loading-view {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 20; }
  /* line 21, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view.unveiled {
    pointer-events: none; }
  /* line 24, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view.preview-screen {
    background-color: rgba(0, 0, 0, 0.5); }
  /* line 27, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view .left-wing, #level-loading-view .right-wing {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none; }
  /* line 33, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view .left-wing {
    background: black;
    background-image: url("/images/level/loading_left_wing_1920.jpg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    left: -50%;
    -webkit-transition: all 1.2s ease;
    transition: all 1.2s ease; }
    @media screen and (max-width: 1366px) {
      /* line 33, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view .left-wing {
        background: black;
        background-image: url("/images/level/loading_left_wing_1366.jpg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain; } }
  /* line 40, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view .right-wing {
    background: black;
    background-image: url("/images/level/loading_right_wing_1920.jpg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    right: -50%;
    -webkit-transition: all 1.2s ease;
    transition: all 1.2s ease; }
    @media screen and (max-width: 1366px) {
      /* line 40, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view .right-wing {
        background: black;
        background-image: url("/images/level/loading_right_wing_1366.jpg");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain; } }
  /* line 47, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-loading-view #loading-details {
    position: absolute;
    top: 86px;
    right: 50%;
    width: 450px;
    height: 450px;
    margin-right: -225px;
    z-index: 100;
    background: transparent url(/images/level/code_editor_background.png) no-repeat;
    background-size: 100% 100%;
    color: darkslategray;
    font-size: 15px;
    padding: 4% 80px 40px 80px;
    text-align: center;
    -webkit-transition: 1.2s cubic-bezier(0.285, -0.595, 0.67, -0.6);
    transition: 1.2s cubic-bezier(0.285, -0.595, 0.67, -0.6);
    font-family: 'Open Sans Condensed'; }
    /* line 66, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details.preview {
      top: 0;
      right: 0;
      margin-right: 0;
      width: 45%;
      height: auto;
      pointer-events: all;
      -webkit-transition: 1.2s ease-in-out;
      transition: 1.2s ease-in-out;
      padding: 80px 70px 40px 50px; }
      /* line 76, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details.preview .progress-or-start-container.intro-footer {
        bottom: 30px; }
    /* line 79, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details .level-loading-goals {
      text-align: left; }
      /* line 82, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .level-loading-goals .goals-title {
        font-size: 32px;
        color: black;
        font-weight: bold; }
      /* line 87, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .level-loading-goals li {
        font-size: 20px;
        color: black; }
    /* line 91, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details .intro-doc {
      text-align: left;
      font-size: 16px; }
      /* line 96, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .intro-doc img {
        max-width: 100%; }
      /* line 99, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .intro-doc pre {
        box-sizing: content-box;
        border: 5px solid white;
        padding: 0; }
        /* line 105, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
        #level-loading-view #loading-details .intro-doc pre .ace_content .ace_marker-layer .ace_bracket {
          display: none; }
        /* line 108, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
        #level-loading-view #loading-details .intro-doc pre .ace_content .ace_cursor-layer .ace_cursor {
          display: none; }
    /* line 111, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details .progress-or-start-container {
      position: absolute;
      bottom: 95px;
      height: 80px;
      left: 48px;
      right: 77px;
      -webkit-transition: bottom 1.2s ease-out;
      transition: bottom 1.2s ease-out; }
      /* line 119, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .progress-or-start-container .load-progress {
        width: 100%;
        height: 45px;
        margin: 20px auto 0 auto; }
        /* line 124, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
        #level-loading-view #loading-details .progress-or-start-container .load-progress .progress {
          height: 100%;
          position: relative;
          background-color: transparent;
          -webkit-box-shadow: none;
          box-shadow: none; }
          /* line 130, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
          #level-loading-view #loading-details .progress-or-start-container .load-progress .progress .progress-background {
            width: 100%;
            height: 100%;
            background: transparent url(/images/level/loading_bar_back.png) no-repeat;
            background-size: 100% 100%;
            position: absolute;
            z-index: -1; }
          /* line 138, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
          #level-loading-view #loading-details .progress-or-start-container .load-progress .progress .progress-bar-container {
            width: 75%;
            height: 100%;
            left: 10%;
            position: absolute; }
            /* line 144, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
            #level-loading-view #loading-details .progress-or-start-container .load-progress .progress .progress-bar-container .progress-bar {
              width: 1%;
              height: 100%;
              transition-duration: 0;
              background: transparent url(/images/level/loading_bar_fill.png) no-repeat;
              background-size: 325px 100%;
              -webkit-box-shadow: none;
              box-shadow: none; }
          /* line 152, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
          #level-loading-view #loading-details .progress-or-start-container .load-progress .progress.active .progress-bar {
            transition-duration: 1.2s; }
          /* line 155, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
          #level-loading-view #loading-details .progress-or-start-container .load-progress .progress .rim {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent url(/images/level/loading_bar_rim.png) no-repeat;
            background-size: 100% 100%; }
      /* line 164, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .progress-or-start-container .start-level-button {
        display: none;
        width: 100%;
        height: auto;
        margin: 0px auto;
        font-size: 40px;
        line-height: 45px;
        font-variant: small-caps;
        text-transform: none; }
    /* line 174, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details .subscription-required, #level-loading-view #loading-details .course-membership-required, #level-loading-view #loading-details .could-not-load, #level-loading-view #loading-details .license-required {
      display: none;
      margin-top: 40px;
      color: black;
      font-size: 24px; }
      /* line 180, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
      #level-loading-view #loading-details .subscription-required .btn, #level-loading-view #loading-details .course-membership-required .btn, #level-loading-view #loading-details .could-not-load .btn, #level-loading-view #loading-details .license-required .btn {
        width: 100%;
        margin: 20px auto 0px;
        font-size: 40px;
        font-variant: small-caps; }
    /* line 186, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details #tip-wrapper {
      position: absolute;
      z-index: 2;
      bottom: 40px;
      left: 25px;
      width: 401px;
      color: #666; }
    /* line 194, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
    #level-loading-view #loading-details.preview #tip-wrapper {
      left: 48px;
      right: 77px;
      width: auto; }

@media screen and (min-height: 900px) {
  /* line 201, /home/coco/codecombat/app/styles/play/level/level-loading-view.sass */
  #level-view.web-dev #loading-details.preview {
    background: transparent;
    border: 1px solid transparent;
    border-width: 124px 76px 64px 40px;
    border-image: url(/images/level/code_editor_background.png) 124 76 64 40 fill round;
    padding: 0 35px 0 15px; } }
