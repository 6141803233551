/* line 2, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view .algolia-autocomplete, #convert-to-teacher-account-view .algolia-autocomplete, #request-quote-view .algolia-autocomplete {
  width: 100%; }
  /* line 5, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
  #create-teacher-account-view .algolia-autocomplete .aa-input, #convert-to-teacher-account-view .algolia-autocomplete .aa-input, #request-quote-view .algolia-autocomplete .aa-input {
    width: 100%; }
  /* line 8, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
  #create-teacher-account-view .algolia-autocomplete .aa-hint, #convert-to-teacher-account-view .algolia-autocomplete .aa-hint, #request-quote-view .algolia-autocomplete .aa-hint {
    color: #999;
    width: 100%; }
  /* line 12, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
  #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu, #request-quote-view .algolia-autocomplete .aa-dropdown-menu {
    background-color: #fff;
    border: 1px solid #999;
    border-top: none;
    width: 100%; }
    /* line 18, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
    #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion, #request-quote-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
      cursor: pointer;
      padding: 5px 4px;
      border-top: 1px solid #ccc; }
      /* line 23, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
      #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .school, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .school, #request-quote-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .school {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold; }
      /* line 29, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
      #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district, #request-quote-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 20px; }
        /* line 34, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
        #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district span, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district span, #request-quote-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion .district span {
          white-space: nowrap; }
    /* line 38, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
    #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor, #request-quote-view .algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
      background-color: #B2D7FF; }
    /* line 41, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
    #create-teacher-account-view .algolia-autocomplete .aa-dropdown-menu em, #convert-to-teacher-account-view .algolia-autocomplete .aa-dropdown-menu em, #request-quote-view .algolia-autocomplete .aa-dropdown-menu em {
      font-weight: bold;
      font-style: normal; }

/* line 47, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view .control-label, #convert-to-teacher-account-view .control-label, #request-quote-view .control-label {
  font-weight: bold;
  width: 100%; }

/* line 51, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view .error-help-block, #convert-to-teacher-account-view .error-help-block, #request-quote-view .error-help-block {
  margin-top: inherit;
  margin-bottom: 0px;
  float: right;
  font-size: 13px;
  font-style: italic;
  font-weight: normal; }

/* line 59, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view .text-muted, #convert-to-teacher-account-view .text-muted, #request-quote-view .text-muted {
  float: right;
  font-size: 13px;
  font-style: italic;
  font-weight: normal; }

/* line 65, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view .nullify-form-control, #convert-to-teacher-account-view .nullify-form-control, #request-quote-view .nullify-form-control {
  display: inherit;
  width: inherit;
  height: inherit;
  padding: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  vertical-align: inherit;
  background-color: inherit;
  background-image: inherit;
  border: inherit;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  -webkit-transition: inherit;
  transition: inherit; }

/* line 84, /home/coco/codecombat/app/styles/teachers/teacher-trial-requests.sass */
#create-teacher-account-view input[name='email'] ~ .help-block, #convert-to-teacher-account-view input[name='email'] ~ .help-block, #request-quote-view input[name='email'] ~ .help-block {
  display: none; }
