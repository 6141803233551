/* line 4, /home/coco/codecombat/app/styles/play/spectate.sass */
#spectate-level-view {
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  min-width: 1024px;
  position: relative; }
  /* line 5, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #playback-view {
    width: 100%; }
  /* line 7, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #restart-button {
    display: none; }
  /* line 9, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #multiplayer-button {
    display: none; }
  /* line 11, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #docs-button {
    display: none; }
  /* line 13, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #gold-view {
    right: 1%; }
  /* line 15, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #duel-stats-view {
    right: 230px;
    bottom: 80px;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2); }
  /* line 20, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #control-bar-view {
    width: 100%; }
    /* line 23, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view #control-bar-view .title {
      position: absolute;
      display: inline-block;
      color: #BEBEBE;
      line-height: 15px;
      left: 20%;
      width: 60%;
      text-align: center; }
  /* line 35, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .level-content {
    position: relative;
    margin: 0px auto; }
  /* line 39, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #canvas-wrapper {
    top: 50px;
    width: 100%;
    position: relative;
    margin: 0 auto; }
  /* line 45, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view canvas#webgl-surface, #spectate-level-view canvas#normal-surface {
    display: block;
    z-index: 1;
    margin: 0 auto; }
  /* line 50, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view canvas#webgl-surface {
    background-color: #333; }
  /* line 53, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view canvas#normal-surface {
    position: absolute;
    top: 0;
    pointer-events: none; }
  /* line 60, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #thang-hud {
    margin: 0 auto;
    display: none; }
  /* line 65, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #pointer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    opacity: 0.0;
    pointer-events: none;
    z-index: 10; }
  /* line 75, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .ui-effects-transfer {
    border: 2px dotted gray; }
  /* line 79, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .modal img {
    float: right; }
  /* line 82, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .modal img.diagram {
    float: none; }
  /* line 85, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #multiplayer-join-link {
    font-size: 12px; }
  /* line 88, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #level-done-button {
    position: absolute;
    right: 46%;
    top: 43px;
    -webkit-box-shadow: 4px 4px 15px black;
    box-shadow: 4px 4px 15px black; }
  /* line 95, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .btn.banner {
    background-color: #ededed;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e8e8e8), color-stop(16%, #e8e8e8), color-stop(17%, #FFF), color-stop(83%, #FFF), color-stop(84%, #e8e8e8), to(#e8e8e8));
    background-image: -webkit-linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: -moz-linear-gradient(top, #e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: -o-linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-repeat: no-repeat;
    color: #333;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid black;
    text-shadow: none; }
    /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
    #spectate-level-view .btn.banner:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner:focus:not(.disabled):not([disabled]) {
      background-color: #f9f9f9;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f7f7), color-stop(16%, #f7f7f7), color-stop(17%, white), color-stop(83%, white), color-stop(84%, #f7f7f7), to(#f7f7f7));
      background-image: -webkit-linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: -moz-linear-gradient(top, #f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: -o-linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-repeat: no-repeat; }
    /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
    #spectate-level-view .btn.banner.active, #spectate-level-view .btn.banner:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
    /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
    #spectate-level-view .btn.banner.disabled, #spectate-level-view .btn.banner[disabled] {
      cursor: default;
      background-image: none;
      opacity: 0.65;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-primary {
      background-color: #4c95e5;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4490e4), color-stop(16%, #4490e4), color-stop(17%, #6CA8EA), color-stop(83%, #6CA8EA), color-stop(84%, #4490e4), to(#4490e4));
      background-image: -webkit-linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: -moz-linear-gradient(top, #4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: -o-linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-primary:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-primary:focus:not(.disabled):not([disabled]) {
        background-color: #67a5e9;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5fa0e8), color-stop(16%, #5fa0e8), color-stop(17%, #87b8ee), color-stop(83%, #87b8ee), color-stop(84%, #5fa0e8), to(#5fa0e8));
        background-image: -webkit-linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: -moz-linear-gradient(top, #5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: -o-linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-primary.active, #spectate-level-view .btn.banner.btn-primary:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-primary.disabled, #spectate-level-view .btn.banner.btn-primary[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-info {
      background-color: #569ac2;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4f96c0), color-stop(16%, #4f96c0), color-stop(17%, #71AACC), color-stop(83%, #71AACC), color-stop(84%, #4f96c0), to(#4f96c0));
      background-image: -webkit-linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: -moz-linear-gradient(top, #4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: -o-linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-info:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-info:focus:not(.disabled):not([disabled]) {
        background-color: #6ca7ca;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#66a3c8), color-stop(16%, #66a3c8), color-stop(17%, #88b7d4), color-stop(83%, #88b7d4), color-stop(84%, #66a3c8), to(#66a3c8));
        background-image: -webkit-linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: -moz-linear-gradient(top, #66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: -o-linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-info.active, #spectate-level-view .btn.banner.btn-info:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-info.disabled, #spectate-level-view .btn.banner.btn-info[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-success {
      background-color: #79962d;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#748f2b), color-stop(16%, #748f2b), color-stop(17%, #90B236), color-stop(83%, #90B236), color-stop(84%, #748f2b), to(#748f2b));
      background-image: -webkit-linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: -moz-linear-gradient(top, #748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: -o-linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-success:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-success:focus:not(.disabled):not([disabled]) {
        background-color: #8cac35;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#87a632), color-stop(16%, #87a632), color-stop(17%, #a1c541), color-stop(83%, #a1c541), color-stop(84%, #87a632), to(#87a632));
        background-image: -webkit-linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: -moz-linear-gradient(top, #87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: -o-linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-success.active, #spectate-level-view .btn.banner.btn-success:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-success.disabled, #spectate-level-view .btn.banner.btn-success[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-warning {
      background-color: #a85500;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9f5100), color-stop(16%, #9f5100), color-stop(17%, #CD6800), color-stop(83%, #CD6800), color-stop(84%, #9f5100), to(#9f5100));
      background-image: -webkit-linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: -moz-linear-gradient(top, #9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: -o-linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-warning:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-warning:focus:not(.disabled):not([disabled]) {
        background-color: #c76500;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#be6000), color-stop(16%, #be6000), color-stop(17%, #ec7800), color-stop(83%, #ec7800), color-stop(84%, #be6000), to(#be6000));
        background-image: -webkit-linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: -moz-linear-gradient(top, #be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: -o-linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-warning.active, #spectate-level-view .btn.banner.btn-warning:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-warning.disabled, #spectate-level-view .btn.banner.btn-warning[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-danger {
      background-color: #95321a;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8d2f19), color-stop(16%, #8d2f19), color-stop(17%, #B43C20), color-stop(83%, #B43C20), color-stop(84%, #8d2f19), to(#8d2f19));
      background-image: -webkit-linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: -moz-linear-gradient(top, #8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: -o-linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-danger:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-danger:focus:not(.disabled):not([disabled]) {
        background-color: #af3a1f;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#a7381e), color-stop(16%, #a7381e), color-stop(17%, #ce4525), color-stop(83%, #ce4525), color-stop(84%, #a7381e), to(#a7381e));
        background-image: -webkit-linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: -moz-linear-gradient(top, #a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: -o-linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-danger.active, #spectate-level-view .btn.banner.btn-danger:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-danger.disabled, #spectate-level-view .btn.banner.btn-danger[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 103, /home/coco/codecombat/app/styles/play/spectate.sass */
    #spectate-level-view .btn.banner.btn-inverse {
      background-color: #2e4366;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2c3e5f), color-stop(16%, #2c3e5f), color-stop(17%, #3A537F), color-stop(83%, #3A537F), color-stop(84%, #2c3e5f), to(#2c3e5f));
      background-image: -webkit-linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: -moz-linear-gradient(top, #2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: -o-linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-inverse:hover:not(.disabled):not([disabled]), #spectate-level-view .btn.banner.btn-inverse:focus:not(.disabled):not([disabled]) {
        background-color: #38507b;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#354c74), color-stop(16%, #354c74), color-stop(17%, #446194), color-stop(83%, #446194), color-stop(84%, #354c74), to(#354c74));
        background-image: -webkit-linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: -moz-linear-gradient(top, #354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: -o-linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-repeat: no-repeat; }
      /* line 31, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-inverse.active, #spectate-level-view .btn.banner.btn-inverse:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /home/coco/codecombat/app/styles/mixins.sass */
      #spectate-level-view .btn.banner.btn-inverse.disabled, #spectate-level-view .btn.banner.btn-inverse[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
  /* line 108, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view .gradient {
    position: absolute;
    z-index: 10; }
  /* line 112, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #code-area-gradient {
    top: 0px;
    width: 3px;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    left: -3px;
    bottom: 0; }
  /* line 119, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #canvas-left-gradient {
    left: 0px;
    width: 5px;
    background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
    bottom: -30px;
    top: 0; }
  /* line 126, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #canvas-top-gradient {
    top: 0;
    height: 5px;
    left: 0;
    right: 0;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.4) 100%); }
  /* line 133, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #hud-left-gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
    left: 0;
    top: 0;
    height: 100%;
    width: 2%; }
  /* line 140, /home/coco/codecombat/app/styles/play/spectate.sass */
  #spectate-level-view #hud-right-gradient {
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    right: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 2%; }
