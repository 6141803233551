/* line 2, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
#editor-level-components-tab-view h3 {
  margin-top: 0; }
  @media screen and (max-width: 800px) {
    /* line 2, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view h3 {
      display: none; } }

/* line 7, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
#editor-level-components-tab-view .toggle {
  padding: 6px 8px;
  z-index: 11;
  margin-top: 1px;
  margin-left: 2px; }

/* line 13, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
#editor-level-components-tab-view .components-container {
  position: absolute;
  top: 0;
  bottom: 0; }
  /* line 18, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
  #editor-level-components-tab-view .components-container .editor-nano-container {
    position: relative;
    height: 90%;
    width: 250px; }
    /* line 23, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .components-container .editor-nano-container .treema-root {
      position: absolute;
      bottom: 0;
      width: 250px;
      overflow: scroll; }
      /* line 29, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
      #editor-level-components-tab-view .components-container .editor-nano-container .treema-root .treema-children .treema-row * {
        cursor: pointer !important; }
    /* line 31, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .components-container .editor-nano-container .nano-pane {
      z-index: 12; }
    /* line 33, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .components-container .editor-nano-container #components-treema {
      z-index: 11; }
      /* line 36, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
      #editor-level-components-tab-view .components-container .editor-nano-container #components-treema .not-present {
        opacity: 0.75; }

/* line 39, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
#editor-level-components-tab-view .edit-component-container {
  margin-left: 290px;
  position: absolute;
  right: 0;
  left: 0px;
  top: 0;
  bottom: 0; }
  @media screen and (max-width: 800px) {
    /* line 39, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .edit-component-container {
      margin-left: 0px; }
      /* line 49, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
      #editor-level-components-tab-view .edit-component-container .nav-tabs {
        margin-left: 80px; }
        /* line 52, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
        #editor-level-components-tab-view .edit-component-container .nav-tabs li {
          z-index: 11; } }
  /* line 55, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
  #editor-level-components-tab-view .edit-component-container .treema-root {
    position: absolute;
    top: 35px;
    right: 0;
    left: 0px;
    bottom: 0;
    overflow: scroll; }
  /* line 63, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
  #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select {
    position: absolute;
    top: 0;
    right: 0;
    left: auto; }
    @media screen and (max-width: 800px) {
      /* line 63, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
      #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select {
        left: 40px;
        top: 1px;
        bottom: auto;
        padding: 8px 10px; } }
    /* line 73, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select .text {
      display: block; }
      @media screen and (max-width: 800px) {
        /* line 73, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
        #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select .text {
          display: none; } }
    /* line 77, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
    #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select [class^='icon-'] {
      display: none; }
      @media screen and (max-width: 800px) {
        /* line 77, /home/coco/codecombat/app/styles/editor/level/components_tab.sass */
        #editor-level-components-tab-view .edit-component-container #create-new-component-button-no-select [class^='icon-'] {
          display: block; } }
