/* line 6, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
.thang-avatar-view.selected .thang-avatar-wrapper {
  -webkit-box-shadow: 0px 0px 10px #4f4fd5;
  box-shadow: 0px 0px 10px #4f4fd5; }

/* line 9, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
.thang-avatar-view .thang-avatar-wrapper {
  width: 100%;
  position: relative;
  background-color: #4f4f4f;
  background-image: -webkit-linear-gradient(top, #bdbdbd 0%, #4f4f4f 100%);
  background-image: linear-gradient(to bottom, #bdbdbd 0%, #4f4f4f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFBDBDBD', endColorstr='#FF4F4F4F', GradientType=0);
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#bdbdbd 20%), to(#4f4f4f 70%));
  background-image: -webkit-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
  background-image: -moz-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
  background-image: -o-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
  background-image: radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
  background-repeat: no-repeat; }
  /* line 14, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper.team-humans {
    border-color: darkred;
    background-color: #e62b1e;
    background-image: -webkit-linear-gradient(top, #f28f88 0%, #e62b1e 100%);
    background-image: linear-gradient(to bottom, #f28f88 0%, #e62b1e 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF28F88', endColorstr='#FFE62B1E', GradientType=0);
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#f28f88 20%), to(#e62b1e 70%));
    background-image: -webkit-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
    background-image: -moz-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
    background-image: -o-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
    background-image: radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
    background-repeat: no-repeat; }
  /* line 18, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper.team-ogres {
    border-color: darkblue;
    background-color: #005c9e;
    background-image: -webkit-linear-gradient(top, #7ac8ff 0%, #005c9e 100%);
    background-image: linear-gradient(to bottom, #7ac8ff 0%, #005c9e 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF7AC8FF', endColorstr='#FF005C9E', GradientType=0);
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#7ac8ff 20%), to(#005c9e 70%));
    background-image: -webkit-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
    background-image: -moz-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
    background-image: -o-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
    background-image: radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
    background-repeat: no-repeat; }
  /* line 22, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper.team-allies, .thang-avatar-view .thang-avatar-wrapper.team-minions {
    border-color: darkgreen;
    background-color: #188e10;
    background-image: -webkit-linear-gradient(top, #8ff288 0%, #188e10 100%);
    background-image: linear-gradient(to bottom, #8ff288 0%, #188e10 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF8FF288', endColorstr='#FF188E10', GradientType=0);
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#8ff288 20%), to(#188e10 70%));
    background-image: -webkit-radial-gradient(circle, #8ff288 20%, #188e10 70%);
    background-image: -moz-radial-gradient(circle, #8ff288 20%, #188e10 70%);
    background-image: -o-radial-gradient(circle, #8ff288 20%, #188e10 70%);
    background-image: radial-gradient(circle, #8ff288 20%, #188e10 70%);
    background-repeat: no-repeat; }
  /* line 26, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper .avatar {
    width: 100%; }
  /* line 29, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper .avatar-frame {
    position: absolute;
    left: -18%;
    top: -19%;
    width: 145%; }
  /* line 35, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
  .thang-avatar-view .thang-avatar-wrapper .badge {
    position: absolute;
    top: -4px;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 0;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 16px;
    background-color: #aaa; }
    /* line 52, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
    .thang-avatar-view .thang-avatar-wrapper .badge.problems {
      left: -4px; }
      /* line 55, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
      .thang-avatar-view .thang-avatar-wrapper .badge.problems.error {
        background-color: red; }
      /* line 57, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
      .thang-avatar-view .thang-avatar-wrapper .badge.problems.warning {
        background-color: #f89406; }
      /* line 59, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
      .thang-avatar-view .thang-avatar-wrapper .badge.problems.info {
        background-color: #3a87ad; }
    /* line 62, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
    .thang-avatar-view .thang-avatar-wrapper .badge.shared-thangs {
      background-color: blue;
      right: -4px; }

/* line 66, /home/coco/codecombat/app/styles/play/level/thang_avatar.sass */
.thang-avatar-view .thang-name {
  font-variant: small-caps;
  text-align: center;
  color: #ca8;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  white-space: nowrap; }
