/* line 2, /home/coco/codecombat/app/styles/teachers/course-nag-modal.sass */
#course-nag-modal .row {
  margin-bottom: 20px; }

/* line 5, /home/coco/codecombat/app/styles/teachers/course-nag-modal.sass */
#course-nag-modal .instructions {
  font-size: 12pt; }

/* line 8, /home/coco/codecombat/app/styles/teachers/course-nag-modal.sass */
#course-nag-modal .italic {
  font-style: italic; }

/* line 11, /home/coco/codecombat/app/styles/teachers/course-nag-modal.sass */
#course-nag-modal .size-by-height {
  max-height: 230px; }

/* line 14, /home/coco/codecombat/app/styles/teachers/course-nag-modal.sass */
#course-nag-modal .size-by-width {
  width: 100%; }
