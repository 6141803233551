/* line 6, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .modal-dialog {
  margin: 60px auto 0 auto;
  padding: 0;
  height: 460px;
  width: 700px;
  background: none; }

/* line 13, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .modal-content {
  height: 100%;
  width: 100%; }

/* line 17, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .background-img {
  position: absolute;
  top: -61px;
  left: 0px;
  height: 100%;
  width: 100%; }

/* line 24, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .wizard-img {
  position: absolute;
  top: 70px;
  left: 32px;
  height: 301px; }

/* line 30, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .blurb-container {
  height: 290px;
  position: absolute;
  right: 50px;
  top: 70px;
  margin: 0;
  width: 300px; }
  /* line 38, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
  #share-progress-modal .blurb-container h1 {
    font-size: 28px;
    font-weight: bold;
    color: black; }
  /* line 43, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
  #share-progress-modal .blurb-container .send-container {
    margin-top: 10px; }
    /* line 46, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
    #share-progress-modal .blurb-container .send-container .email-form .email-input {
      width: 200px; }
    /* line 49, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
    #share-progress-modal .blurb-container .send-container button {
      color: white;
      width: 80px;
      font-size: 28px;
      line-height: 28px;
      text-transform: none;
      font-variant: small-caps;
      font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    /* line 57, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
    #share-progress-modal .blurb-container .send-container button.back-btn {
      border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
    /* line 59, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
    #share-progress-modal .blurb-container .send-container button.send-btn {
      border-image: url(/images/level/code_toolbar_submit_button_active.png) 14 20 20 20 fill round; }
  /* line 62, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
  #share-progress-modal .blurb-container .continue-container {
    position: absolute;
    bottom: 0; }
  /* line 65, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
  #share-progress-modal .blurb-container .continue-link {
    color: black;
    font-weight: normal;
    font-size: 11px;
    text-decoration: underline; }
  /* line 71, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
  #share-progress-modal .blurb-container .email-invalid {
    color: red;
    display: none; }

/* line 77, /home/coco/codecombat/app/styles/play/modal/share-progress-modal.sass */
#share-progress-modal .alert {
  position: absolute;
  left: 10%;
  width: 80%;
  top: 20px;
  border: 5px solid gray; }
