/* line 7, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal h1 {
  position: absolute;
  left: 200px;
  top: -70px;
  margin: 0;
  width: 612px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0; }

/* line 19, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal .modal-dialog {
  margin: 150px auto 0 auto;
  padding: 0;
  width: 1060px;
  height: 460px;
  background: none; }

/* line 27, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal #buy-gems-background {
  position: absolute;
  top: -157px;
  left: -2px; }

/* line 34, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal #close-modal {
  position: absolute;
  left: 770px;
  top: -80px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 47, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
  #buy-gems-modal #close-modal:hover {
    color: yellow; }

/* line 52, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal #products {
  position: absolute;
  left: 55px;
  top: 242px;
  width: 960px;
  height: 140px; }
  /* line 59, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
  #buy-gems-modal #products .product {
    width: 228px;
    height: 136px;
    float: left;
    text-align: center;
    margin-right: 12px;
    position: relative; }
    /* line 67, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
    #buy-gems-modal #products .product h4 {
      font-size: 20px;
      color: #161005; }
    /* line 71, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
    #buy-gems-modal #products .product h3 {
      margin-top: 10px;
      text-transform: uppercase;
      color: #161005; }
    /* line 76, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
    #buy-gems-modal #products .product button {
      position: absolute;
      width: 80%;
      left: 10%;
      bottom: -30px; }

/* line 84, /home/coco/codecombat/app/styles/play/modal/buy-gems-modal.sass */
#buy-gems-modal .alert {
  position: absolute;
  left: 10%;
  width: 80%;
  top: 20px;
  border: 5px solid gray; }
