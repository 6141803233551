/* line 4, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
#starter-license-upsell-view .tall-margin, #starter-license-upsell-view .tall-margin-top {
  margin-top: 70px; }

/* line 7, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
#starter-license-upsell-view .tall-margin, #starter-license-upsell-view .tall-margin-bottom {
  margin-bottom: 50px; }

/* line 11, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
#starter-license-upsell-view .checkmark-blurb .fa-check {
  color: #20572B;
  font-size: 50px; }

/* line 15, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
#starter-license-upsell-view .feature-comparison-table {
  margin: auto; }
  /* line 17, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table tr:nth-child(2n+3) {
    background: #F8F8F8; }
    /* line 19, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
    #starter-license-upsell-view .feature-comparison-table tr:nth-child(2n+3):first-child, #starter-license-upsell-view .feature-comparison-table tr:nth-child(2n+3):last-child {
      background: white; }
  /* line 21, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table th, #starter-license-upsell-view .feature-comparison-table td {
    text-align: center;
    font-size: 15px;
    padding: 0 10px; }
  /* line 26, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table tr:first-child th.special-offer-col {
    border: thin solid #0E4C60;
    background: #0E4C60;
    color: white; }
  /* line 31, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table tr:last-child th.special-offer-col {
    border-left: thin solid #0E4C60;
    border-right: thin solid #0E4C60;
    border-bottom: thin solid #0E4C60; }
  /* line 35, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table tr:last-child .btn {
    margin: 10px 0 15px;
    padding: 10px 30px; }
  /* line 39, /home/coco/codecombat/app/styles/teachers/starter-license-upsell-view.sass */
  #starter-license-upsell-view .feature-comparison-table td.special-offer-col, #starter-license-upsell-view .feature-comparison-table th.special-offer-col {
    border-left: thin solid #0E4C60;
    border-right: thin solid #0E4C60; }
