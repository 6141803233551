/* line 1, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
#codeplayback-view {
  border: 1px black solid;
  padding: 8px;
  width: 50%;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 8px; }
  /* line 8, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view .number, #codeplayback-view #slider-container {
    display: inline-block; }
  /* line 10, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view .number {
    text-align: center;
    width: 12.5%; }
  /* line 13, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #slider-container {
    width: 75%;
    position: relative; }
  /* line 16, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #codearea, #codeplayback-view #slider {
    width: 100%; }
  /* line 18, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #codearea {
    height: 384px; }
  /* line 20, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #acearea {
    height: 384px;
    width: 100%; }
  /* line 23, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #play-container {
    float: left; }
  /* line 25, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #speed-container {
    float: right; }
  /* line 27, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view #control-container {
    min-height: 24px; }
  /* line 29, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view .event {
    position: absolute;
    background-color: rgba(100, 100, 255, 0.125);
    left: 7px;
    top: 0px;
    width: 1px;
    height: 16px;
    pointer-events: none; }
  /* line 37, /home/coco/codecombat/app/styles/admin/codeplayback-view.sass */
  #codeplayback-view .clicked {
    background-color: orange; }
