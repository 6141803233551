/* line 8, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
.ui-effects-transfer {
  outline: 2px solid #28f;
  -webkit-box-shadow: 0 0 10px #28f;
  box-shadow: 0 0 10px #28f;
  z-index: 9001; }

/* line 13, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
#inventory-modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }
  /* line 16, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal .modal-dialog {
    margin: 30px auto 0 auto;
    width: 1017px;
    height: 660px; }
  /* line 21, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal .modal-content {
    height: 100%;
    width: 100%; }
  /* line 25, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal .modal-body {
    height: 450px;
    margin: 0; }
  @media only screen and (max-height: 728px) {
    /* line 32, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .modal-content {
      top: -55px; } }
  /* line 38, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #play-items-modal-narrow-bg {
    position: absolute;
    top: -69px;
    left: -8px; }
  /* line 46, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal h1 {
    position: absolute;
    left: 109px;
    top: 22px;
    margin: 0;
    width: 255px;
    text-align: center;
    color: #febc44;
    font-size: 38px;
    text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0; }
  /* line 60, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #gems-count-container {
    position: absolute;
    left: 374px;
    top: 10px;
    width: 160px;
    height: 66px;
    -webkit-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg); }
    /* line 68, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #gems-count-container #gems-count {
      position: absolute;
      left: 75px;
      top: 14px;
      font-size: 25px;
      color: #01405b; }
  /* line 78, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #close-modal {
    position: absolute;
    left: 551px;
    top: 21px;
    width: 60px;
    height: 60px;
    color: white;
    text-align: center;
    font-size: 30px;
    padding-top: 7px;
    cursor: pointer;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg); }
    /* line 91, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #close-modal:hover {
      color: yellow; }
  /* line 97, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #equipped {
    width: 330px;
    position: absolute;
    left: 20px;
    top: 122px;
    height: 450px;
    overflow: hidden; }
    /* line 105, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #equipped .item-slot {
      width: 48px;
      height: 48px;
      margin: 5px;
      background-color: rgba(255, 255, 255, 0.4);
      border: 2px dashed #646496;
      position: relative;
      cursor: pointer;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease;
      z-index: 20; }
      /* line 120, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot.selected .placeholder, #inventory-modal #equipped .item-slot.selected .item {
        border-color: #5199ec;
        background-color: #5199ec;
        -webkit-box-shadow: 0 0 10px #5199ec;
        box-shadow: 0 0 10px #5199ec; }
      /* line 124, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot.selected .item {
        background: #5199ec; }
      /* line 127, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot.should-equip {
        background-color: #8fa;
        outline: 2px solid #8af;
        -webkit-box-shadow: 2px 2px 4px black;
        box-shadow: 2px 2px 4px black; }
      /* line 132, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot.droppable {
        outline: 2px solid blue;
        -webkit-box-shadow: 4px 4px 6px black;
        box-shadow: 4px 4px 6px black; }
      /* line 136, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot.droppable-hover {
        outline: 4px solid blue;
        -webkit-box-shadow: 6px 6px 8px black;
        box-shadow: 6px 6px 8px black; }
      /* line 140, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot .placeholder {
        width: 100%;
        height: 100%;
        background-size: cover;
        opacity: 0.7;
        filter: alpha(opacity=70);
        background-image: url(/images/pages/game-menu/slot-icons.png); }
      /* line 150, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot] {
        position: absolute;
        top: 10px;
        left: 10px; }
      /* line 155, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="misc-1"] {
        display: none;
        left: 10px;
        top: 168px; }
        /* line 159, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="misc-1"] .placeholder {
          background-position: 0px 0px; }
      /* line 162, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="misc-0"] {
        display: none;
        left: 10px;
        top: 117px; }
        /* line 166, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="misc-0"] .placeholder {
          background-position: -176px 0px; }
      /* line 169, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="minion"] {
        left: 265px;
        top: 66px; }
        /* line 172, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="minion"] .placeholder {
          background-position: -44px 0px; }
      /* line 175, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="programming-book"] {
        left: 10px;
        top: 15px; }
        /* line 178, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="programming-book"] .placeholder {
          background-position: -88px 0px; }
      /* line 181, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="wrists"] {
        left: 10px;
        top: 117px; }
        /* line 184, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="wrists"] .placeholder {
          background-position: -220px 0px; }
      /* line 187, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="left-ring"] {
        left: 214px;
        top: 15px; }
        /* line 190, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="left-ring"] .placeholder {
          background-position: -264px 0px; }
      /* line 193, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="right-ring"] {
        left: 61px;
        top: 15px; }
        /* line 196, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="right-ring"] .placeholder {
          background-position: -308px 0px; }
      /* line 199, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="torso"] {
        left: 265px;
        top: 168px; }
        /* line 202, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="torso"] .placeholder {
          background-position: -352px 0px; }
      /* line 205, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="feet"] {
        left: 10px;
        top: 382.2px; }
        /* line 208, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="feet"] .placeholder {
          background-position: -396px 0px; }
      /* line 211, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="neck"] {
        left: 163px;
        top: 15px; }
        /* line 214, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="neck"] .placeholder {
          background-position: -440px 0px; }
      /* line 217, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="waist"] {
        left: 10px;
        top: 66px; }
        /* line 220, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="waist"] .placeholder {
          background-position: -484px 0px; }
      /* line 223, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="eyes"] {
        left: 112px;
        top: 15px; }
        /* line 226, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="eyes"] .placeholder {
          background-position: -528px 0px; }
      /* line 229, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="head"] {
        left: 265px;
        top: 117px; }
        /* line 232, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="head"] .placeholder {
          background-position: -572px 0px; }
      /* line 235, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="pet"] {
        left: 265px;
        top: 382.2px; }
        /* line 238, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="pet"] .placeholder {
          background-position: -616px 0px; }
      /* line 241, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="gloves"] {
        left: 10px;
        top: 168px; }
        /* line 244, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="gloves"] .placeholder {
          background-position: -660px 0px; }
      /* line 247, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="left-hand"] {
        left: 265px;
        top: 219px; }
        /* line 250, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="left-hand"] .placeholder {
          background-position: -704px 0px; }
      /* line 253, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="right-hand"] {
        left: 10px;
        top: 219px; }
        /* line 256, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="right-hand"] .placeholder {
          background-position: -748px 0px; }
      /* line 259, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot[data-slot="flag"] {
        left: 265px;
        top: 15px; }
        /* line 262, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #equipped .item-slot[data-slot="flag"] .placeholder {
          background-position: -88px 0px; }
      /* line 265, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #equipped .item-slot .item {
        position: absolute;
        left: -2px;
        top: -2px;
        width: 48px;
        height: 48px;
        border: 2px solid black;
        background-color: white; }
  /* line 276, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal.Wizard #equipped .item-slot[data-slot="right-hand"] .placeholder {
    background-position: -880px 0px; }
  /* line 278, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal.Wizard #equipped .item-slot[data-slot="left-hand"] .placeholder {
    background-position: -132px 0px; }
  /* line 282, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal.Ranger #equipped .item-slot[data-slot="right-hand"] .placeholder {
    background-position: -792px 0px; }
  /* line 284, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal.Ranger #equipped .item-slot[data-slot="left-hand"] .placeholder {
    background-position: -836px 0px; }
  /* line 292, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #equipped.droppable {
    outline: 2px solid blue;
    -webkit-box-shadow: 4px 4px 6px black;
    box-shadow: 4px 4px 6px black; }
  /* line 296, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #equipped.droppable-hover {
    outline: 4px solid blue;
    -webkit-box-shadow: 6px 6px 8px black;
    box-shadow: 6px 6px 8px black; }
  /* line 300, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal .draggable-item {
    width: 57.6px;
    height: 57.6px; }
    /* line 304, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .draggable-item button {
      display: none; }
  /* line 310, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal.Warrior #unequipped .item:not(.Warrior), #inventory-modal.Ranger #unequipped .item:not(.Ranger), #inventory-modal.Wizard #unequipped .item:not(.Wizard) {
    visibility: hidden;
    position: absolute; }
  /* line 315, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #unequipped {
    width: 245px;
    position: absolute;
    left: 370px;
    top: 135px;
    height: 435px;
    padding: 9px 0 9px 9px; }
    /* line 323, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #unequipped #double-click-hint {
      margin: 20px 40px 60px 0;
      border: 3px solid #8585f4;
      padding: 5px;
      font-weight: bold; }
      /* line 328, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped #double-click-hint .glyphicon {
        margin-right: 5px;
        position: relative;
        top: 2px; }
    /* line 333, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #unequipped h4 {
      clear: both;
      margin-bottom: 10px;
      margin-top: 20px;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bold; }
    /* line 341, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #unequipped .item {
      float: left;
      border: 1px solid black;
      margin: 3px;
      padding: 1px;
      position: relative;
      width: 60px; }
      /* line 349, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item:not(.equipped):not(.restricted) {
        cursor: pointer; }
        /* line 352, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #unequipped .item:not(.equipped):not(.restricted):hover {
          padding: 0; }
          /* line 354, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
          #inventory-modal #unequipped .item:not(.equipped):not(.restricted):hover img {
            margin: 1px; }
          /* line 356, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
          #inventory-modal #unequipped .item:not(.equipped):not(.restricted):hover button {
            font-size: 12px;
            margin: -1px 1px 1px 1px; }
            /* line 362, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
            #inventory-modal #unequipped .item:not(.equipped):not(.restricted):hover button:active, #inventory-modal #unequipped .item:not(.equipped):not(.restricted):hover button.active {
              background-position: -114px 0;
              color: white;
              text-shadow: 0 1px 0 black, 1px 0 0 black, -1px 0 0 black, 0 -1px 0 black; }
      /* line 367, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item img {
        width: 56px;
        height: 56px;
        display: block; }
      /* line 372, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item button {
        background: transparent url(/images/pages/play/modal/equip-buttons.png);
        width: 56px;
        height: 21px;
        border: 0;
        font-size: 11px;
        border-radius: 1px;
        padding: 0 0 2px 0;
        -webkit-transition: font-size 0.1s ease;
        transition: font-size 0.1s ease;
        text-transform: uppercase;
        font-weight: bold;
        color: black;
        text-spacing: 0px; }
      /* line 390, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item.active {
        background-color: #5199ec; }
        /* line 393, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal #unequipped .item.active button {
          box-shadow: 1px 1px 4px #333;
          color: white;
          text-shadow: 0 1px 0 black, 1px 0 0 black, -1px 0 0 black, 0 -1px 0 black;
          background-position: -57px 0; }
      /* line 403, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item.should-equip {
        background-color: #8fa;
        outline: 2px solid #8af;
        -webkit-box-shadow: 4px 4px 6px black;
        box-shadow: 4px 4px 6px black;
        z-index: 1; }
      /* line 413, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item.equipped {
        background-color: #ff5;
        display: none; }
      /* line 423, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item.restricted {
        background-color: #bebebe;
        -webkit-filter: contrast(50%) brightness(100%);
        -moz-filter: contrast(50%) brightness(100%);
        -o-filter: contrast(50%) brightness(100%);
        filter: contrast(50%) brightness(100%); }
      /* line 433, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #unequipped .item.locked:not(:hover) {
        -webkit-filter: contrast(75%);
        -moz-filter: contrast(75%);
        -o-filter: contrast(75%);
        filter: contrast(75%); }
  /* line 438, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #choose-hero-button, #inventory-modal #play-level-button {
    top: 582px;
    position: absolute;
    background: url(/images/pages/play/modal/confirm-button.png);
    width: 209px;
    height: 55px;
    background-size: 209px 55px;
    border: 0; }
    /* line 447, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #choose-hero-button:disabled, #inventory-modal #play-level-button:disabled {
      opacity: 1;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%); }
  /* line 452, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #choose-hero-button {
    left: 20px; }
  /* line 455, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #play-level-button {
    right: 414px; }
  /* line 463, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #item-details-view #item-title {
    left: 698px;
    top: 56px; }
  /* line 467, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #item-details-view #item-details-body {
    left: 650px; }
  /* line 470, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #item-details-view #selected-item-unlock-button {
    left: 646px; }
  /* line 473, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #item-details-view .unequippable {
    display: none; }
  /* line 479, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #item-details-extra {
    position: absolute;
    left: 644px;
    top: 589px; }
    /* line 484, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #item-details-extra > * {
      width: 338px;
      height: 50px; }
    /* line 488, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #item-details-extra .alert {
      text-align: center;
      font-weight: bold; }
    /* line 492, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #item-details-extra button {
      border: 3px solid #2e2e2e;
      background: white;
      font-size: 16px; }
    /* line 497, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #item-details-extra #equip-item-viewed {
      background: #54802c;
      color: white; }
    /* line 501, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #item-details-extra #subscriber-item-viewed {
      flex-grow: 1;
      height: 55px;
      line-height: 10px;
      border-style: solid;
      border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
      border-width: 14px 20px 20px 20px;
      color: #f2f2f2;
      background-color: black; }
      /* line 511, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #item-details-extra #subscriber-item-viewed span {
        pointer-events: none; }
      /* line 514, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #item-details-extra #subscriber-item-viewed:hover {
        border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
      /* line 517, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #item-details-extra #subscriber-item-viewed:active {
        border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
        padding: 2px 0 0 2px;
        color: white; }
  /* line 524, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #hero-image, #inventory-modal #hero-image-head, #inventory-modal #hero-image-hair, #inventory-modal #hero-image-thumb {
    display: block;
    position: absolute;
    z-index: 12; }
    /* line 529, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #hero-image.female, #inventory-modal #hero-image-head.female, #inventory-modal #hero-image-hair.female, #inventory-modal #hero-image-thumb.female {
      left: 80px;
      bottom: 31px; }
      /* line 533, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #hero-image.female.Ranger, #inventory-modal #hero-image-head.female.Ranger, #inventory-modal #hero-image-hair.female.Ranger, #inventory-modal #hero-image-thumb.female.Ranger {
        left: -7px; }
    /* line 536, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #hero-image.male, #inventory-modal #hero-image-head.male, #inventory-modal #hero-image-hair.male, #inventory-modal #hero-image-thumb.male {
      left: 65px;
      bottom: 31px; }
      /* line 540, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal #hero-image.male.Ranger, #inventory-modal #hero-image-head.male.Ranger, #inventory-modal #hero-image-hair.male.Ranger, #inventory-modal #hero-image-thumb.male.Ranger {
        left: -16px; }
  /* line 543, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #hero-image-head {
    z-index: 16; }
  /* line 545, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #hero-image-hair {
    z-index: 17; }
  /* line 547, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal #hero-image-thumb {
    z-index: 16; }
    /* line 550, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #hero-image-thumb.female:not(.Ranger) {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
      left: 66px;
      bottom: 54px; }
    /* line 555, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal #hero-image-thumb.male:not(.Ranger) {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
      left: 53px;
      bottom: 54px; }
  /* line 560, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
  #inventory-modal .doll-image {
    position: absolute;
    z-index: 15; }
    /* line 564, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.male {
      left: 65px;
      bottom: 31px; }
    /* line 568, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.female {
      left: 80px;
      bottom: 31px; }
    /* line 572, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.feet {
      z-index: 13; }
      /* line 575, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.feet.male.Ranger {
        bottom: 45px; }
    /* line 578, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.right-hand:not(.Ranger) {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg); }
      /* line 580, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.right-hand:not(.Ranger).female {
        left: 66px;
        bottom: 54px; }
      /* line 584, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.right-hand:not(.Ranger).male {
        left: 55px;
        bottom: 63px; }
    /* line 588, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.right-hand.female.Ranger[src="/file/db/thang.type/53f4e6e3d822c23505b74f42/warrior_female.png"] {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      left: -95px;
      bottom: -89px; }
    /* line 594, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.right-hand.male.Ranger[src="/file/db/thang.type/53f4e6e3d822c23505b74f42/warrior_male.png"] {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      left: -138px;
      bottom: -55px; }
    /* line 600, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.left-hand {
      z-index: 17; }
    /* line 603, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.torso {
      z-index: 14; }
      /* line 606, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.torso.male-back, #inventory-modal .doll-image.torso.female-back {
        z-index: 11; }
    /* line 609, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.gloves {
      z-index: 15; }
      /* line 613, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.gloves.female.female-thumb, #inventory-modal .doll-image.gloves.female.female-ranger-thumb {
        z-index: 16; }
        /* line 615, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal .doll-image.gloves.female.female-thumb:not(.Ranger), #inventory-modal .doll-image.gloves.female.female-ranger-thumb:not(.Ranger) {
          -webkit-transform: rotate(-15deg);
          -ms-transform: rotate(-15deg);
          transform: rotate(-15deg);
          left: 66px;
          bottom: 54px; }
      /* line 621, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
      #inventory-modal .doll-image.gloves.male.male-thumb, #inventory-modal .doll-image.gloves.male.male-ranger-thumb {
        z-index: 16; }
        /* line 623, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
        #inventory-modal .doll-image.gloves.male.male-thumb:not(.Ranger), #inventory-modal .doll-image.gloves.male.male-ranger-thumb:not(.Ranger) {
          -webkit-transform: rotate(-15deg);
          -ms-transform: rotate(-15deg);
          transform: rotate(-15deg);
          left: 53px;
          bottom: 54px; }
    /* line 628, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.head {
      z-index: 17; }
    /* line 632, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.Ranger:not(.feet).female {
      left: -7px; }
    /* line 635, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.Ranger:not(.feet).male {
      left: -16px; }
    /* line 638, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
    #inventory-modal .doll-image.pet {
      z-index: 20; }

/* line 642, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
body[lang='ja'] #inventory-modal #unequipped .item button, body[lang^='zh'] #inventory-modal #unequipped .item button, body[lang='ca'] #inventory-modal #unequipped .item button, body[lang^='es'] #inventory-modal #unequipped .item button, body[lang^='pt'] #inventory-modal #unequipped .item button, body[lang='ro'] #inventory-modal #unequipped .item button, body[lang='fi'] #inventory-modal #unequipped .item button, body[lang='sv'] #inventory-modal #unequipped .item button, body[lang='uk'] #inventory-modal #unequipped .item button, body[lang='vi'] #inventory-modal #unequipped .item button, body[lang='cz'] #inventory-modal #unequipped .item button {
  font-weight: normal;
  letter-spacing: -1px;
  padding-bottom: 1px; }

/* line 648, /home/coco/codecombat/app/styles/play/menu/inventory-modal.sass */
body[lang='ro'] #inventory-modal #unequipped .item button, body[lang='fi'] #inventory-modal #unequipped .item button, body[lang='uk'] #inventory-modal #unequipped .item button {
  text-transform: none; }
