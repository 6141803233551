/* line 1, /home/coco/codecombat/app/styles/application.sass */
h1 {
  text-shadow: none;
  border-radius: 5px; }

/* line 5, /home/coco/codecombat/app/styles/application.sass */
.editor-widget {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; }

/* line 14, /home/coco/codecombat/app/styles/application.sass */
.centering_container {
  position: absolute;
  left: 25%;
  bottom: 5%;
  z-index: 1; }

/* line 20, /home/coco/codecombat/app/styles/application.sass */
#console {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0; }

/* line 27, /home/coco/codecombat/app/styles/application.sass */
#submit {
  position: relative;
  left: -50%;
  z-index: 1; }

/* line 33, /home/coco/codecombat/app/styles/application.sass */
html .lt-ie7, html .lt-ie8, html .lt-ie9, html .lt-ie10 {
  display: none; }

/* line 35, /home/coco/codecombat/app/styles/application.sass */
html.lt-ie7 .lt-ie7 {
  display: block; }

/* line 37, /home/coco/codecombat/app/styles/application.sass */
html.lt-ie8 .lt-ie8 {
  display: block; }

/* line 39, /home/coco/codecombat/app/styles/application.sass */
html.lt-ie9 .lt-ie9 {
  display: block; }

/* line 41, /home/coco/codecombat/app/styles/application.sass */
html.lt-ie10 .lt-ie10 {
  display: block; }
