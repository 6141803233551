/* line 5, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
#teacher-dashboard-nav {
  vertical-align: middle;
  text-transform: uppercase; }
  /* line 9, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
  #teacher-dashboard-nav .navbar {
    border-radius: 0;
    background: #0E4C60; }
    /* line 13, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar .navbar-toggle {
      border-color: white; }
    /* line 16, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar .icon-bar {
      background-color: white; }
    /* line 19, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar .navbar-brand {
      color: white;
      padding-top: 11px;
      padding-bottom: 11px; }
    /* line 24, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar li > a {
      font-family: "Open Sans", sans-serif;
      padding: 13px 12px 21px 12px; }
    /* line 31, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar li > a:hover {
      background-color: white; }
      /* line 33, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
      #teacher-dashboard-nav .navbar li > a:hover small {
        color: #0E4C60; }
    /* line 37, /home/coco/codecombat/app/styles/courses/teacher-dashboard-nav.sass */
    #teacher-dashboard-nav .navbar li.active .label {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-left: 0.6em;
      margin-right: 0.6em;
      border-bottom: 4px solid white;
      border-radius: 0; }
