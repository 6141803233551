/* line 3, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view #site-content-area {
  width: 100%; }

/* line 6, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view td {
  max-width: 120px;
  overflow: hidden; }

/* line 10, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view .btn-deny {
  float: right; }

/* line 13, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view .status-cell {
  width: 120px; }

/* line 16, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view td.created {
  min-width: 90px; }

/* line 19, /home/coco/codecombat/app/styles/admin/trial-requests.sass */
#admin-trial-requests-view td.reviewed {
  min-width: 90px; }
