/* line 1, /home/coco/codecombat/app/styles/cla.sass */
#cla-view {
  background-color: white;
  padding: 50px 0 200px; }
  /* line 8, /home/coco/codecombat/app/styles/cla.sass */
  #cla-view #cla {
    width: 800px;
    margin: 0 auto;
    box-sizing: border-box; }
  /* line 13, /home/coco/codecombat/app/styles/cla.sass */
  #cla-view hr {
    border: 1px solid black; }
