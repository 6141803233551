/* line 4, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
#account-settings-root-view #site-content-area {
  width: auto;
  max-width: 1024px;
  padding-bottom: 44px; }

/* line 9, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
#account-settings-root-view #save-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: gray;
  padding: 5px; }
  /* line 18, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
  #account-settings-root-view #save-button-container #save-button {
    width: 100%; }
    /* line 21, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
    #account-settings-root-view #save-button-container #save-button.btn-info, #account-settings-root-view #save-button-container #save-button.btn-danger {
      opacity: 1.0; }

/* line 24, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
#account-settings-view {
  padding-top: 20px; }
  /* line 28, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
  #account-settings-view .panel-heading {
    font-family: Open Sans Condensed;
    font-weight: bold; }
    /* line 32, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
    #account-settings-view .panel-heading .panel-title {
      font-size: 20px; }
    /* line 35, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
    #account-settings-view .panel-heading #delete-account-panel-title {
      color: #F00; }
  /* line 39, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
  #account-settings-view .profile-photo {
    max-width: 100%;
    max-height: 200px;
    display: block;
    margin-bottom: 10px; }
  /* line 46, /home/coco/codecombat/app/styles/account/account-settings-view.sass */
  #account-settings-view #email-panel #specific-notification-settings {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid gray; }
