/* line 4, /home/coco/codecombat/app/styles/teachers/purchase-starter-licenses-modal.sass */
#purchase-starter-licenses-modal p:not(.small) {
  font-size: 16px;
  line-height: 20px; }

/* line 8, /home/coco/codecombat/app/styles/teachers/purchase-starter-licenses-modal.sass */
#purchase-starter-licenses-modal .input-row span, #purchase-starter-licenses-modal .input-row input {
  padding: 0 10px; }

/* line 10, /home/coco/codecombat/app/styles/teachers/purchase-starter-licenses-modal.sass */
#purchase-starter-licenses-modal .input-row input {
  border-radius: 5px;
  height: 36px;
  background-color: white;
  border: 1px solid #0E4C60;
  color: #0E4C60; }

/* line 17, /home/coco/codecombat/app/styles/teachers/purchase-starter-licenses-modal.sass */
#purchase-starter-licenses-modal .pay-now-btn {
  border: none; }

/* line 20, /home/coco/codecombat/app/styles/teachers/purchase-starter-licenses-modal.sass */
#purchase-starter-licenses-modal button[disabled] {
  opacity: 0.70; }
