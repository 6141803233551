/* line 2, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view #patch-submit {
  margin-top: 5px; }

/* line 5, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view .translation-table td {
  width: 40%; }

/* line 8, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view .outer-content {
  padding: 10px;
  overflow-y: scroll; }

/* line 12, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view select {
  margin-bottom: 10px; }

/* line 15, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view td.accepted {
  background: lightgreen; }

/* line 18, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view td.rejected, .i18n-edit-model-view td.withdrawn {
  background: pink; }

/* line 21, /home/coco/codecombat/app/styles/i18n/i18n-edit-model-view.sass */
.i18n-edit-model-view td.pending {
  background: lightyellow; }
