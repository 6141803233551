/* line 4, /home/coco/codecombat/app/styles/modal/create-account-modal/school-info-panel.sass */
.school-info-panel .suggestion-wrapper {
  position: relative; }
  /* line 6, /home/coco/codecombat/app/styles/modal/create-account-modal/school-info-panel.sass */
  .school-info-panel .suggestion-wrapper .list-group {
    position: absolute;
    z-index: 1;
    width: 100%; }
    /* line 11, /home/coco/codecombat/app/styles/modal/create-account-modal/school-info-panel.sass */
    .school-info-panel .suggestion-wrapper .list-group .list-group-item.active {
      background: #0E4C60;
      color: white; }
