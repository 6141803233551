/* line 1, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
#campaign-level-view {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3; }
  /* line 9, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
  #campaign-level-view .tasks {
    padding: 15px; }
  /* line 12, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
  #campaign-level-view .button.close {
    font-size: 63px; }
  /* line 15, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
  #campaign-level-view .line-graph-label {
    font-size: 10pt;
    font-weight: normal; }
  /* line 18, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
  #campaign-level-view .line-graph-container {
    height: 500px;
    width: 100%;
    position: relative; }
    /* line 22, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
    #campaign-level-view .line-graph-container .x.axis {
      font-size: 9pt; }
      /* line 24, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
      #campaign-level-view .line-graph-container .x.axis path {
        display: none; }
    /* line 26, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
    #campaign-level-view .line-graph-container .y.axis {
      font-size: 9pt; }
      /* line 28, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
      #campaign-level-view .line-graph-container .y.axis path {
        display: none; }
    /* line 30, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
    #campaign-level-view .line-graph-container .key-line {
      font-size: 9pt; }
    /* line 32, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
    #campaign-level-view .line-graph-container .key-text {
      font-size: 9pt; }
    /* line 34, /home/coco/codecombat/app/styles/editor/campaign/campaign-level-view.sass */
    #campaign-level-view .line-graph-container .graph-point-info-container {
      display: none;
      position: absolute;
      padding: 10px;
      border: 1px solid black;
      z-index: 3;
      background-color: blanchedalmond;
      font-size: 10pt; }
