/* line 2, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view nav {
  margin-bottom: 0; }

/* line 5, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view #component-patches {
  padding: 0 10px 10px;
  background: white; }

/* line 9, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view .patches-view {
  padding: 10px 20px 10px 0px; }

/* line 12, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view .navbar-text {
  float: left; }

/* line 15, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view #component-code-editor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 35px;
  border: 2px solid black;
  border-top: none; }

/* line 24, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view .inner-editor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px; }

/* line 31, /home/coco/codecombat/app/styles/editor/level/component/level-component-edit-view.sass */
#level-component-edit-view #patch-component-button {
  display: none; }
