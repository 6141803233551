/* line 1, /home/coco/codecombat/app/styles/test-view.sass */
#test-view {
  background-color: #eee;
  padding: 0; }
  /* line 5, /home/coco/codecombat/app/styles/test-view.sass */
  #test-view #test-h2 {
    background: #add8e6;
    font-family: Arial, Geneva, sans-serif;
    padding: 20px;
    font-weight: bold; }
  /* line 11, /home/coco/codecombat/app/styles/test-view.sass */
  #test-view .alert-report {
    font-size: 20px; }
