/* line 1, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
#components-documentation-view {
  background-color: #e4cf8c;
  height: calc(100% - 90px); }
  /* line 5, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
  #components-documentation-view #toggle-all-component-code {
    margin: 10px; }
  /* line 8, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
  #components-documentation-view .container, #components-documentation-view .row {
    height: 100%; }
  /* line 11, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
  #components-documentation-view .index-column, #components-documentation-view .documentation-column {
    overflow-x: hidden; }
    /* line 14, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
    #components-documentation-view .index-column > ul, #components-documentation-view .documentation-column > ul {
      padding: 0px 20px 20px 20px; }
    /* line 17, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
    #components-documentation-view .index-column .doc-name, #components-documentation-view .documentation-column .doc-name {
      color: saddlebrown; }
  /* line 20, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
  #components-documentation-view .index-column {
    width: 25%; }
  /* line 23, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
  #components-documentation-view .documentation-column {
    width: 75%; }
    /* line 26, /home/coco/codecombat/app/styles/docs/components-documentation-view.sass */
    #components-documentation-view .documentation-column .special-list, #components-documentation-view .documentation-column .doc-description, #components-documentation-view .documentation-column .code-block {
      list-style-type: none; }
