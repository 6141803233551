/* line 3, /home/coco/codecombat/app/styles/careers.sass */
#careers-view .big-side-margins {
  margin-left: 15%;
  margin-right: 15%; }

/* line 7, /home/coco/codecombat/app/styles/careers.sass */
#careers-view .big-text {
  font-size: 16px; }

/* line 10, /home/coco/codecombat/app/styles/careers.sass */
#careers-view .center {
  text-align: center; }

/* line 13, /home/coco/codecombat/app/styles/careers.sass */
#careers-view .praise-author {
  font-size: 12px; }

/* line 16, /home/coco/codecombat/app/styles/careers.sass */
#careers-view .praise-quote {
  font-size: 20px;
  font-style: italic; }
