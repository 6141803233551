/* line 2, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#thang-type-edit-view #portrait {
  float: left;
  margin: 5px;
  width: 100px;
  height: 100px; }

/* line 8, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#thang-type-edit-view #settings-col {
  width: 560px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0; }
  /* line 15, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #settings-col .file-controls {
    float: left;
    position: relative;
    top: 5px;
    left: 5px; }
    /* line 20, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
    #thang-type-edit-view #settings-col .file-controls button {
      margin-left: 5px; }
  /* line 22, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #settings-col input[type="file"] {
    display: none; }
  /* line 25, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #settings-col #thang-type-file-size {
    width: 350px;
    float: left;
    margin: 20px 10px; }
  /* line 30, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #settings-col .treema-row img {
    max-width: 100%; }
  /* line 33, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #settings-col .treema-root {
    overflow: scroll;
    box-sizing: border-box;
    position: absolute;
    top: 160px;
    bottom: 20px;
    right: 20px;
    left: 20px; }

/* line 42, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#thang-type-edit-view #display-col {
  position: absolute;
  width: 440px;
  left: 580px;
  top: 0;
  bottom: 0;
  overflow: hidden; }
  /* line 50, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #display-col > div.slider-cell {
    margin-right: 5px; }
  /* line 53, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #display-col canvas {
    width: 400px;
    border: 1px solid blue;
    background-color: lightgray; }
  /* line 58, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #display-col select {
    margin-top: 10px; }
  /* line 61, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #display-col #marker-button, #thang-type-edit-view #display-col #play-button, #thang-type-edit-view #display-col #stop-button {
    margin: 10px 10px 10px 0; }
  /* line 64, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #display-col .slider-cell {
    margin: 5px 0;
    width: 195px;
    float: left; }
    /* line 69, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
    #thang-type-edit-view #display-col .slider-cell .selector {
      display: inline-block;
      width: 180px;
      position: relative;
      top: 3px; }

/* line 76, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#thang-type-edit-view #thang-components-edit-view {
  position: absolute;
  top: 0px;
  bottom: 0; }
  /* line 81, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #thang-type-edit-view #thang-components-edit-view .treema-root {
    background-color: white;
    border-radius: 4px; }

/* line 85, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#thang-type-edit-view .play-with-level-input {
  margin: 5px; }

/* line 89, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
#spritesheets {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid green;
  overflow: scroll; }
  /* line 97, /home/coco/codecombat/app/styles/editor/thang/thang-type-edit-view.sass */
  #spritesheets canvas {
    background: darkseagreen; }
