/* line 8, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal .modal-dialog {
  width: 820px;
  height: 570px;
  padding: 0;
  background: none;
  position: relative;
  top: 40px; }

/* line 19, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal #game-menu-background {
  position: absolute;
  top: -146px;
  left: -3px; }

/* line 27, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal #close-modal {
  position: absolute;
  left: 769px;
  top: -5px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 17px;
  cursor: pointer;
  z-index: 2;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 41, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
  #game-menu-modal #close-modal:hover {
    color: yellow; }

/* line 47, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal #game-menu-nav {
  position: absolute;
  top: 53px;
  left: 42px;
  width: 178px; }
  /* line 53, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
  #game-menu-modal #game-menu-nav li {
    background: url(/images/pages/play/modal/menu-tab.png);
    padding: 5px;
    margin: -5px 0;
    height: 80px;
    padding: 0; }
    /* line 60, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
    #game-menu-modal #game-menu-nav li.active {
      background: url(/images/pages/play/modal/menu-tab-selected.png);
      width: 197px; }
    /* line 64, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
    #game-menu-modal #game-menu-nav li a {
      font-size: 18px;
      line-height: 50px;
      background: none;
      color: #c3997c;
      font-weight: bold;
      padding: 14px 20px;
      font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase; }
      /* line 74, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
      #game-menu-modal #game-menu-nav li a .glyphicon {
        margin-right: 6px; }

/* line 80, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal .game-menu-tab-content {
  position: absolute;
  left: 219px;
  top: 21px;
  width: 571px;
  height: 495px;
  margin-top: 15px;
  padding: 50px;
  overflow-y: scroll; }

/* line 90, /home/coco/codecombat/app/styles/play/menu/game-menu-modal.sass */
#game-menu-modal ::-webkit-scrollbar {
  display: none; }
