/* line 5, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
#problem-alert-view.problem-alert {
  z-index: 10;
  position: absolute;
  top: 45px;
  right: 500px;
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-size: 18px;
  text-shadow: none;
  color: white;
  word-wrap: break-word;
  border-style: solid;
  border-image: url(/images/level/code_editor_error_background.png) 16 20 fill round;
  border-width: 16px 20px; }

@-webkit-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-moz-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-ms-keyframes jiggle {
  /* line 24, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert 0% {
    transform: rotate(0deg); }
  /* line 26, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert 25% {
    transform: rotate(1deg); }
  /* line 28, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert 50% {
    transform: rotate(0deg); }
  /* line 30, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert 75% {
    transform: rotate(-1deg); }
  /* line 32, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert 100% {
    transform: rotate(0deg); } }

@-o-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }
  /* line 35, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert.jiggling {
    -webkit-animation: jiggle 0.3s infinite;
    animation: jiggle 0.3s infinite; }
  /* line 38, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert.no-hint {
    padding: 7px; }
  /* line 42, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert .problem-alert-title {
    color: white;
    border-bottom: 1px solid white; }
  /* line 46, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert .close {
    opacity: 0.8;
    filter: alpha(opacity=80);
    text-shadow: none;
    cursor: pointer;
    color: white;
    float: right; }
    /* line 53, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
    #problem-alert-view.problem-alert .close:hover, #problem-alert-view.problem-alert .close:focus {
      opacity: 1;
      filter: alpha(opacity=100); }
  /* line 56, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert .problem-subtitle {
    font-size: 80%; }
  /* line 61, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert.alert-warning {
    border-image-source: url(/images/level/code_editor_warning_background.png); }
  /* line 64, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  #problem-alert-view.problem-alert.alert-info {
    border-image-source: url(/images/level/code_editor_info_background.png); }

/* line 68, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
html.no-borderimage .problem-alert {
  border-width: 0;
  border-image: none;
  background: transparent url(/images/level/code_editor_error_background.png) no-repeat;
  background-size: 100% 100%;
  padding: 18px 35px 18px 14px; }
  /* line 75, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  html.no-borderimage .problem-alert.alert-warning {
    background-image: url(/images/level/code_editor_warning_background.png); }
  /* line 78, /home/coco/codecombat/app/styles/play/level/tome/problem_alert.sass */
  html.no-borderimage .problem-alert.alert-info {
    background-image: url(/images/level/code_editor_info_background.png); }
