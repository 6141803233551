/* line 5, /home/coco/codecombat/app/styles/premium-features-view.sass */
#premium-features-view {
  overflow: hidden; }
  /* line 8, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .burgundy {
    background-color: #7D0101; }
  /* line 10, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .forest {
    background-color: #20572B; }
  /* line 12, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .navy {
    background-color: #0E4C60; }
  /* line 16, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .center-text {
    text-align: center !important; }
  /* line 18, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .top-offset-200 {
    margin-top: 200px !important; }
  /* line 20, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .bottom-offset-72 {
    margin-bottom: 72px !important; }
  /* line 22, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .force-right-265 {
    right: 265px !important; }
  /* line 24, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .force-width-265 {
    width: 265px !important; }
  /* line 27, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view #bannerImg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 600px;
    margin-top: -50px;
    filter: drop-shadow(4px 4px 8px black);
    pointer-events: none; }
  /* line 36, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .feature-row {
    height: 256px;
    margin: 64px; }
    /* line 41, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .feature-row .left-text {
      text-align: left; }
    /* line 43, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .feature-row .right-text {
      text-align: right; }
  /* line 46, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .void {
    height: 256px;
    width: 2000px;
    position: absolute;
    overflow: hidden; }
    /* line 52, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-text {
      position: absolute;
      width: 256px;
      padding: 30px 40px; }
      /* line 56, /home/coco/codecombat/app/styles/premium-features-view.sass */
      #premium-features-view .void .void-text h2 {
        color: white; }
    /* line 59, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-text-left {
      right: 0; }
    /* line 61, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-text-right {
      left: 0; }
    /* line 64, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-image {
      position: absolute; }
    /* line 66, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-image-left {
      right: 256px; }
    /* line 68, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void .void-image-right {
      left: 256px; }
    /* line 71, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void #heroes-void-image {
      top: -50px;
      width: 375px;
      height: 375px;
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1); }
    /* line 80, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void #pets-void-image {
      top: 10px;
      width: 275px;
      height: 275px; }
    /* line 84, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view .void #game-dev-void-image {
      top: -32px;
      width: 512px;
      height: 512px; }
  /* line 89, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .void-left {
    right: 0; }
  /* line 91, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .void-right {
    left: 0; }
  /* line 94, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view #jumbotron {
    background-color: #0E4C60;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 512px; }
    /* line 101, /home/coco/codecombat/app/styles/premium-features-view.sass */
    #premium-features-view #jumbotron h1 {
      color: white;
      font-size: 28pt;
      line-height: 36pt;
      text-align: center;
      filter: drop-shadow(2px 2px 4px black); }
  /* line 108, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view #bottom-gradient-blend {
    background: linear-gradient(to top, transparent 12.5%, white);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
  /* line 115, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .top-jumbo {
    background-image: url("/images/pages/features/bg2.png"); }
  /* line 118, /home/coco/codecombat/app/styles/premium-features-view.sass */
  #premium-features-view .bottom-jumbo {
    background-image: url("/images/pages/home/character_jumbotron.png");
    margin-top: 50px;
    margin-bottom: -75px; }
