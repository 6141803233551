/* line 1, /home/coco/codecombat/app/styles/demo.sass */
#demo-view {
  margin: 0 20px; }
  /* line 4, /home/coco/codecombat/app/styles/demo.sass */
  #demo-view h2 {
    background: #add8e6;
    font-family: Arial, Geneva, sans-serif;
    padding: 20px;
    font-weight: bold; }
  /* line 10, /home/coco/codecombat/app/styles/demo.sass */
  #demo-view #demo-wrapper {
    width: 78%; }
  /* line 13, /home/coco/codecombat/app/styles/demo.sass */
  #demo-view #demo-nav {
    width: 20%; }
