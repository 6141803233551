/* line 1, /home/coco/codecombat/app/styles/play/menu/item-view.sass */
.item-mixin, .item-view {
  width: 100%; }
  /* line 4, /home/coco/codecombat/app/styles/play/menu/item-view.sass */
  .item-mixin img, .item-view img {
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 5px; }
  /* line 10, /home/coco/codecombat/app/styles/play/menu/item-view.sass */
  .item-mixin .item-info, .item-view .item-info {
    float: left;
    width: 110px;
    width: -webkit-calc(100% - 50px - 5px);
    width: calc(100% - 50px - 5px); }
  /* line 16, /home/coco/codecombat/app/styles/play/menu/item-view.sass */
  .item-mixin ul, .item-view ul {
    margin-top: 5px; }
  /* line 19, /home/coco/codecombat/app/styles/play/menu/item-view.sass */
  .item-mixin .status-message span, .item-view .status-message span {
    display: none; }
