/* line 3, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
#hero-select-view {
  width: 100%; }
  /* line 6, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
  #hero-select-view .hero-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -50px; }
  /* line 12, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
  #hero-select-view .hero-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 48px 50px; }
  /* line 18, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
  #hero-select-view .hero-avatar {
    margin: 6px;
    background-color: #f8f8f8;
    box-shadow: 0 0 0 1px gray; }
  /* line 23, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
  #hero-select-view .current .hero-avatar {
    box-shadow: 0 0 0 6px gray; }
  /* line 26, /home/coco/codecombat/app/styles/core/hero-select-view.sass */
  #hero-select-view .selected .hero-avatar {
    box-shadow: 0 0 0 6px #F2BE19; }
