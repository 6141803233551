/* line 1, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
#level-thang-edit-view {
  color: black; }
  /* line 4, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
  #level-thang-edit-view .well {
    text-align: center;
    padding: 10px; }
  /* line 8, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
  #level-thang-edit-view #thang-props {
    font-weight: bold; }
    /* line 10, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
    #level-thang-edit-view #thang-props span {
      cursor: pointer; }
  /* line 13, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
  #level-thang-edit-view #all-thangs-link {
    float: left;
    cursor: pointer; }
  /* line 17, /home/coco/codecombat/app/styles/editor/level/thang/level-thang-edit-view.sass */
  #level-thang-edit-view #thang-components-edit-view {
    top: 50px; }
