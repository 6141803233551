/* line 2, /home/coco/codecombat/app/styles/artisans/solution-problems-view.sass */
#solution-problems-view .problemType {
  width: 87.5%; }

/* line 5, /home/coco/codecombat/app/styles/artisans/solution-problems-view.sass */
#solution-problems-view .problemValue {
  width: 12.5%; }

/* line 8, /home/coco/codecombat/app/styles/artisans/solution-problems-view.sass */
#solution-problems-view .problemsTable {
  width: 100%; }
