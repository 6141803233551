/* line 5, /home/coco/codecombat/app/styles/home-view.sass */
#home-view {
  overflow: hidden; }
  /* line 9, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #jumbotron-container-fluid {
    background-image: url("/images/pages/home/character_jumbotron.png");
    background-position: 50% 55%; }
    @media (min-width: 1200px) {
      /* line 9, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid {
        background-size: 100% auto; } }
    @media (max-width: 1200px) {
      /* line 9, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid {
        background-size: 1200px auto; } }
    /* line 19, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #jumbotron-container-fluid .container {
      background-repeat: no-repeat; }
      /* line 22, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid .container .btn {
        margin: 10px 0; }
      /* line 25, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid .container h1 {
        color: white; }
        @media (max-width: 992px) {
          /* line 25, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid .container h1 {
            margin-top: 60px;
            font-size: 33px;
            line-height: 45px; } }
      /* line 32, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid .container .well {
        border: none;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.5);
        margin-top: 10px; }
        /* line 37, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well p.userName {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden; }
        /* line 41, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well #classroom-edition-header {
          margin-top: 10px; }
        /* line 43, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well #educator-header {
          white-space: nowrap; }
        /* line 45, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well #students-header {
          line-height: 25px;
          margin-top: 40px;
          margin-bottom: -5px; }
        /* line 49, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well #learn-to-code-header {
          margin-top: 80px; }
        /* line 51, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well#ideal-tickets-well {
          margin-top: 20px; }
        /* line 53, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well .form-group {
          text-align: left; }
          /* line 55, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid .container .well .form-group label {
            line-height: 14px;
            font-size: 12px; }
        @media (max-width: 992px) {
          /* line 32, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid .container .well {
            margin-top: 320px; } }
        /* line 62, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container .well .btn-gold {
          background: #f0ac00; }
      /* line 72, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid .container #learn-more-row {
        margin-top: 80px; }
        @media (max-height: 700px) {
          /* line 72, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid .container #learn-more-row {
            margin-top: 10px; } }
        /* line 76, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container #learn-more-row h2, #home-view #jumbotron-container-fluid .container #learn-more-row h6 {
          color: white; }
        /* line 78, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container #learn-more-row h6 {
          margin-top: 10px; }
        /* line 80, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid .container #learn-more-row a:hover {
          text-decoration: none; }
        @media (max-width: 992px) {
          /* line 72, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid .container #learn-more-row {
            margin-top: 10px; } }
    /* line 85, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #jumbotron-container-fluid.alt-image {
      background-image: url("/images/pages/home/student_jumbotron.jpg");
      background-position: 60% 35%; }
      @media (max-width: 992px) {
        /* line 89, /home/coco/codecombat/app/styles/home-view.sass */
        #home-view #jumbotron-container-fluid.alt-image .well {
          margin-top: 0px; } }
      /* line 93, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #jumbotron-container-fluid.alt-image .container h1 {
        margin-top: 260px;
        margin-bottom: 20px; }
        @media (max-width: 992px) {
          /* line 93, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid.alt-image .container h1 {
            margin-top: 60px;
            margin-bottom: 60px; } }
        @media (max-width: 768px) {
          /* line 93, /home/coco/codecombat/app/styles/home-view.sass */
          #home-view #jumbotron-container-fluid.alt-image .container h1 {
            margin-top: 60px; } }
  /* line 102, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #classroom-in-box-row {
    margin: 40px 0; }
  /* line 105, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view .top-screenshots {
    margin-bottom: 100px;
    margin-top: 50px; }
    /* line 108, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .top-screenshots .label {
      color: black;
      display: block; }
    /* line 111, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .top-screenshots .screenshots {
      text-align: center; }
      /* line 114, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view .top-screenshots .screenshots .screenshot-grid img {
        display: inline-block;
        margin: 6.5px;
        width: 800px;
        border-radius: 8px; }
  /* line 120, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view .teacher-screenshots {
    padding: 10px; }
    /* line 122, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .teacher-screenshots .label {
      color: black;
      display: block; }
    /* line 125, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .teacher-screenshots .screenshots {
      text-align: center; }
      /* line 128, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view .teacher-screenshots .screenshots .screenshot-grid img {
        display: inline-block;
        margin: 6.5px;
        width: 300px;
        border-radius: 4px; }
  /* line 135, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #screenshot-lightbox .modal-dialog {
    width: auto;
    max-width: 1024px; }
  /* line 140, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #feature-spread-row .col-sm-4 {
    padding: 40px; }
  /* line 143, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #feature-spread-row img {
    margin-bottom: 20px; }
  /* line 146, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view .testimonials-rows {
    background: #0E4C60;
    color: white;
    position: relative;
    padding: 60px 0 40px;
    margin: 100px 0; }
    /* line 153, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .testimonials-rows h3, #home-view .testimonials-rows h6 {
      color: white; }
    /* line 156, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .testimonials-rows .testimonials-filler-left {
      position: absolute;
      width: 2000px;
      left: -2000px;
      top: 0px;
      background: #0E4C60;
      height: 100%; }
    /* line 164, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .testimonials-rows .testimonials-filler-right {
      position: absolute;
      width: 2000px;
      right: -2000px;
      top: 0px;
      background: #0E4C60;
      height: 100%; }
    /* line 172, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .testimonials-rows img {
      margin: 0 auto 10px; }
    /* line 175, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .testimonials-rows .row {
      margin: 20px 0; }
  /* line 178, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #benefit-row-1, #home-view #benefit-row-2, #home-view #benefit-row-3 {
    margin: 50px 0; }
  /* line 181, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #benefit-graphic-1, #home-view #benefit-graphic-2, #home-view #benefit-graphic-3 {
    padding: 30px 40px;
    position: relative;
    min-height: 250px;
    margin-bottom: 20px; }
    /* line 187, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-1 h2, #home-view #benefit-graphic-2 h2, #home-view #benefit-graphic-3 h2 {
      color: white;
      width: 50%; }
  /* line 192, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #benefit-graphic-1 {
    background: #7D0101; }
    /* line 195, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-1 img {
      position: absolute;
      right: 0;
      bottom: 0; }
    /* line 200, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-1 #benefit-graphic-1-filler {
      background: #7D0101;
      height: 100%;
      width: 2000px;
      position: absolute;
      left: 100%;
      top: 0; }
  /* line 208, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #benefit-graphic-2 {
    background: #0E4C60; }
    /* line 211, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-2 h2 {
      float: right; }
    /* line 214, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-2 img {
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 219, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-2 #benefit-graphic-2-filler {
      background: #0E4C60;
      height: 100%;
      width: 2000px;
      position: absolute;
      right: 100%;
      top: 0; }
  /* line 227, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #benefit-graphic-3 {
    background: #20572B; }
    /* line 230, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-3 img {
      position: absolute;
      right: 0;
      bottom: 0; }
    /* line 235, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #benefit-graphic-3 #benefit-graphic-3-filler {
      background: #20572B;
      height: 100%;
      width: 2000px;
      position: absolute;
      left: 100%;
      top: 0; }
  /* line 243, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view .have-an-account {
    font-size: 10pt; }
  /* line 246, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #school-level-label {
    margin: 15px 15px 0 0;
    display: inline-block; }
  /* line 250, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #school-level-dropdown {
    display: inline-block;
    width: 250px; }
  /* line 254, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view .request-demo-row {
    margin: 100px 0; }
    /* line 257, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view .request-demo-row .btn {
      margin: 20px 10px; }
  /* line 260, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #total-hours-header {
    margin-top: 10px; }
  /* line 264, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #courses-row .media {
    width: 222px;
    margin: 20px auto 0;
    border: 1px solid #0E4C60;
    border-radius: 8px;
    padding: 50px 15px 0;
    text-align: center;
    position: relative;
    height: 350px;
    color: #0E4C60; }
    /* line 275, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media:first-child {
      border-color: #20572B;
      color: #20572B; }
      /* line 278, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #courses-row .media:first-child h6 {
        color: #20572B; }
    /* line 281, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media.disabled {
      border-color: #9b9b9b;
      color: #9b9b9b; }
      /* line 285, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #courses-row .media.disabled h6 {
        color: #9b9b9b; }
      /* line 287, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #courses-row .media.disabled .media-object {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%); }
    /* line 290, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media h6 {
      padding: 0 5px;
      color: #0E4C60; }
    /* line 294, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media .media-object {
      width: 147px;
      height: 147px;
      border-radius: 74px;
      background-position: -30px -26px;
      background-repeat: no-repeat;
      background-size: 312px auto;
      margin: 15px auto;
      position: absolute;
      left: 38px;
      left: calc((100% - 147px) / 2);
      bottom: 50px; }
    /* line 307, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media .course-duration {
      position: absolute;
      bottom: 25px;
      width: 192px;
      width: calc(100% - 30px);
      padding: 0; }
    /* line 314, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .media .free-course {
      background-color: #20572B;
      width: 100%;
      height: 33px;
      position: absolute;
      top: 0;
      left: 0px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px; }
      /* line 324, /home/coco/codecombat/app/styles/home-view.sass */
      #home-view #courses-row .media .free-course h6 {
        margin-top: 6px;
        color: white; }
  /* line 328, /home/coco/codecombat/app/styles/home-view.sass */
  #home-view #courses-row .text-center {
    width: 100%;
    margin-top: 30px; }
    /* line 331, /home/coco/codecombat/app/styles/home-view.sass */
    #home-view #courses-row .text-center img {
      margin-bottom: 20px; }
