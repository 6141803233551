/* line 3, /home/coco/codecombat/app/styles/account/invoices-view.sass */
#invoices-view .form #amount {
  width: 100px; }

/* line 5, /home/coco/codecombat/app/styles/account/invoices-view.sass */
#invoices-view .form #description {
  min-width: 400px;
  width: auto; }

/* line 8, /home/coco/codecombat/app/styles/account/invoices-view.sass */
#invoices-view .form #pay-button {
  width: auto; }
