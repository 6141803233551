/* line 2, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .axis path, #my-matches-tab-view .axis line {
  fill: none;
  stroke: #555;
  shape-rendering: crispEdges; }

/* line 6, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .x.axis.path {
  display: none; }

/* line 9, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px; }

/* line 14, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .humans-line {
  fill: none;
  stroke: #bf3f3f;
  stroke-width: 1.5px; }

/* line 19, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .ogres-line {
  fill: none;
  stroke: #3f44bf;
  stroke-width: 1.5px; }

/* line 24, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .axis text {
  stroke: none;
  fill: #555555;
  shape-rendering: crispEdges; }

/* line 29, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view tr.fresh {
  background-color: #39F; }

/* line 31, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view tr.stale {
  opacity: 0.5; }

/* line 33, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view tr.win .state-cell {
  color: #172; }

/* line 35, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view tr.loss .state-cell {
  color: #712; }

/* line 38, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view td {
  padding: 1px 2px; }

/* line 41, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .name-cell {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 47, /home/coco/codecombat/app/styles/play/ladder/my_matches_tab.sass */
#my-matches-tab-view .code-language-cell {
  padding: 0 10px;
  background: transparent url(/images/common/code_languages/javascript_icon.png) no-repeat center center;
  height: 16px; }
