/* line 13, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-dialog {
  width: 850px; }
  @media (max-height: 800px) {
    /* line 13, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
    #create-account-modal .modal-dialog {
      margin-top: 0; } }

/* line 18, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-content {
  display: flex;
  flex-direction: column;
  min-height: 550px;
  max-height: 850px;
  width: 850px;
  text-align: center;
  padding: 0;
  border: none; }

/* line 30, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .close {
  color: white;
  opacity: 0.5;
  right: 7px; }
  /* line 34, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .close:hover {
    opacity: 0.9; }

/* line 38, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-header.student, #create-account-modal .modal-footer.student {
  background-color: #20572B; }

/* line 41, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100px;
  max-height: 10.5vh;
  padding: 0;
  background-color: #0E4C60; }
  /* line 52, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .modal-header h3 {
    color: white; }

/* line 55, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-footer {
  padding: 0;
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0E4C60; }
  /* line 65, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .modal-footer span {
    color: white; }
  /* line 68, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .modal-footer a span {
    text-decoration: underline; }

/* line 71, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal #choose-account-type-view, #create-account-modal #segment-check-view, #create-account-modal #basic-info-view, #create-account-modal #coppa-deny-view, #create-account-modal #single-sign-on-already-exists-view, #create-account-modal #single-sign-on-confirm-view, #create-account-modal #extras-view, #create-account-modal #confirmation-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

/* line 76, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

/* line 81, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .modal-body-content {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0; }

/* line 91, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .history-nav-buttons {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between; }
  /* line 99, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .history-nav-buttons .btn {
    margin: 0; }
  /* line 104, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .history-nav-buttons .back-button:first-child:last-child {
    margin-right: auto; }

/* line 109, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .form-container {
  width: 800px; }
  /* line 112, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-container .form-group {
    text-align: left; }

/* line 115, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .full-name {
  display: flex;
  flex-direction: row; }

/* line 119, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .form-group {
  display: flex;
  flex-direction: column;
  align-content: flex-start; }
  /* line 124, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-group.text-center {
    text-align: center; }
  /* line 127, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-group input {
    height: 40px; }
  /* line 130, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-group.row {
    display: block; }
  /* line 133, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-group.last-initial {
    margin-left: 30px;
    width: auto; }
    /* line 137, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
    #create-account-modal .form-group.last-initial input {
      width: 70px; }
  /* line 140, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .form-group.subscribe {
    width: 100%; }

/* line 145, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .hr-text {
  position: relative; }
  /* line 147, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .hr-text hr {
    width: 430px;
    padding: 0;
    border: none;
    border-top: thin solid #444;
    color: #444; }
  /* line 153, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
  #create-account-modal .hr-text span {
    position: absolute;
    left: 50%;
    top: 0.45em;
    transform: translateX(-50%);
    padding: 0 0.75em;
    font-weight: bold;
    font-size: 10pt;
    background: white; }

/* line 166, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .glyphicon-ok-circle {
  color: green; }

/* line 169, /home/coco/codecombat/app/styles/modal/create-account-modal/create-account-modal.sass */
#create-account-modal .glyphicon-remove-circle {
  color: red; }
