/* line 2, /home/coco/codecombat/app/styles/editor/thang/vector-icon-setup-modal.sass */
#vector-icon-setup-modal select {
  margin-bottom: 20px; }

/* line 5, /home/coco/codecombat/app/styles/editor/thang/vector-icon-setup-modal.sass */
#vector-icon-setup-modal canvas {
  background: lightblue;
  border: 3px solid black;
  margin: 20px auto;
  display: block; }
