/* line 2, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .verifier-row {
  margin-top: 20px; }
  /* line 5, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
  #verifier-view .verifier-row .alert {
    padding: 5px; }

/* line 8, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .campaign-mix, #verifier-view .code-language-mix {
  padding: 5px 20px 5px 5px; }
  /* line 11, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
  #verifier-view .campaign-mix input, #verifier-view .code-language-mix input {
    margin-right: 8px; }

/* line 14, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .test-running {
  color: orange; }

/* line 17, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .test-success {
  color: green; }

/* line 20, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .test-failed {
  color: red; }

/* line 23, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .solution {
  max-height: 200px;
  overflow: auto; }

/* line 27, /home/coco/codecombat/app/styles/editor/verifier/verifier-view.sass */
#verifier-view .lineUnder {
  border-bottom: 1px solid #ccc; }
