/* line 2, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
#segment-check-view .class-code-input-group {
  display: flex;
  flex-direction: row;
  align-items: center; }
  /* line 6, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
  #segment-check-view .class-code-input-group .class-code-input {
    text-align: center; }
  /* line 9, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
  #segment-check-view .class-code-input-group .glyphicon {
    width: 0;
    line-height: 40px;
    font-size: 30px; }

/* line 14, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
#segment-check-view .classroom-name {
  font-size: 26pt; }

/* line 17, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
#segment-check-view .teacher-name {
  font-size: 14pt; }

/* line 20, /home/coco/codecombat/app/styles/modal/create-account-modal/segment-check-view.sass */
#segment-check-view .parent_birthdate {
  font-size: 11pt;
  margin-top: 20px; }
