/* line 8, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .modal-dialog {
  margin-top: 0;
  padding-top: 0;
  width: 750px; }
  /* line 13, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .modal-dialog .modal-content {
    position: relative;
    margin-top: -251px; }

@media only screen and (max-height: 720px) {
  /* line 19, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal.full-achievements .modal-dialog {
    margin-top: -76px; }
    /* line 21, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal.full-achievements .modal-dialog #victory-header {
      background: transparent; } }

@media only screen and (max-height: 640px) {
  /* line 24, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal.full-achievements .modal-dialog {
    margin-top: -130px; }
    /* line 26, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal.full-achievements .modal-dialog #victory-header {
      display: none; } }

/* line 31, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .background-wrapper {
  width: 750px;
  background: transparent;
  border: 0px solid transparent;
  border-style: solid;
  border-image: url("/images/pages/play/level/modal/victory_modal_border_background.png") 250 0 100 0 fill round;
  border-width: 250px 0 100px 0;
  border-radius: 12px; }

/* line 41, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .modal-header {
  border: none;
  position: absolute;
  left: 188px;
  width: 378px;
  height: 134px;
  margin: 0;
  padding: 0; }
  /* line 50, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .modal-header #victory-header {
    position: relative;
    -webkit-transition: 0.5s cubic-bezier(0.18, 0.68, 0.75, 2);
    transition: 0.5s cubic-bezier(0.18, 0.68, 0.75, 2);
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    background: transparent url(/images/pages/play/level/modal/victory_hero.png) no-repeat;
    background-position: center -88px; }
    /* line 61, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .modal-header #victory-header.out {
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0); }
    /* line 64, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .modal-header #victory-header #victory-title {
      display: inline-block;
      margin-top: 74px; }
      /* line 68, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal .modal-header #victory-header #victory-title h1 {
        text-transform: uppercase;
        text-align: center;
        color: #fcc935;
        font-size: 80px;
        margin: 0;
        padding: 0;
        text-shadow: black 8px 8px 0, black -8px -8px 0, black 8px -8px 0, black -8px 8px 0, black 8px 0px 0, black 0px -8px 0, black -8px 0px 0, black 0px 8px 0; }

/* line 79, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .modal-body {
  padding: 0 20px;
  min-height: 30px;
  margin-top: 160px; }

/* line 84, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .achievement-panel {
  background: transparent url("/images/pages/play/level/modal/victory_modal_shelf.png") no-repeat center 73px;
  width: 824px;
  height: 127px;
  margin: 0px -37px 0px -57px;
  position: relative;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-filter: grayscale(100%) brightness(75%);
  -moz-filter: grayscale(100%) brightness(75%);
  -o-filter: grayscale(100%) brightness(75%);
  filter: grayscale(100%) brightness(75%); }
  /* line 98, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .achievement-panel.earned {
    -webkit-filter: none;
    -moz-filter: none;
    -o-filter: none;
    filter: none; }
    /* line 104, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .achievement-panel.earned .achievement-description {
      opacity: 1;
      filter: alpha(opacity=100); }

/* line 107, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .achievement-description {
  opacity: 0.75;
  filter: alpha(opacity=75);
  z-index: 1;
  position: absolute;
  text-align: center;
  left: 95px;
  right: 98px;
  top: 86px;
  color: #fcc935;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 123, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .achievement-rewards {
  position: absolute;
  left: 25px;
  right: 23px;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

/* line 133, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .reward-panel {
  background: url("/images/pages/play/level/modal/reward_plate.png");
  width: 77px;
  height: 85px;
  float: left;
  margin: 0 1.8px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease; }
  /* line 143, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel.hero, #hero-victory-modal .reward-panel.item, #hero-victory-modal .reward-panel.xp, #hero-victory-modal .reward-panel.gems {
    background: url("/images/pages/play/level/modal/reward_plate_wide.png");
    width: 120px;
    height: 83px;
    margin-top: 1px; }
    /* line 149, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .reward-panel.hero .reward-image-container, #hero-victory-modal .reward-panel.item .reward-image-container, #hero-victory-modal .reward-panel.xp .reward-image-container, #hero-victory-modal .reward-panel.gems .reward-image-container {
      left: 33px; }
  /* line 152, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel.animating {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    z-index: 2; }
  /* line 156, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel.numerical #hero-victory-modal .reward-panel.animating .reward-text, #hero-victory-modal .reward-panel .subscribe-text {
    font-size: 18px;
    overflow: visible;
    bottom: 9px; }
  /* line 161, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel .reward-image-container {
    top: 8px;
    left: 11px;
    height: 55px;
    width: 56px;
    position: relative;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s; }
    /* line 171, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .reward-panel .reward-image-container.show {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
    /* line 175, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .reward-panel .reward-image-container.pending-reward-image img {
      -webkit-filter: brightness(2000%) contrast(25%);
      -moz-filter: brightness(2000%) contrast(25%);
      -o-filter: brightness(2000%) contrast(25%);
      filter: brightness(2000%) contrast(25%); }
    /* line 181, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .reward-panel .reward-image-container img {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 56px;
      max-height: 55px; }
  /* line 192, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel .reward-text {
    position: absolute;
    bottom: 6px;
    left: 4px;
    right: 3px;
    height: 15px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  /* line 206, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .reward-panel .subscribe-text {
    position: absolute;
    bottom: 84px;
    left: 4px;
    right: 3px;
    height: 15px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

@-webkit-keyframes rewardPulse {
  from {
    max-width: 56px;
    max-height: 55px; }
  50% {
    width: 66px;
    max-width: 66px;
    max-height: 66px; }
  to {
    max-width: 56px;
    max-height: 55px; } }

@-moz-keyframes rewardPulse {
  from {
    max-width: 56px;
    max-height: 55px; }
  50% {
    width: 66px;
    max-width: 66px;
    max-height: 66px; }
  to {
    max-width: 56px;
    max-height: 55px; } }

@-ms-keyframes rewardPulse {
  /* line 224, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal from {
    max-width: 56px;
    max-height: 55px; }
  /* line 227, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal 50% {
    width: 66px;
    max-width: 66px;
    max-height: 66px; }
  /* line 231, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal to {
    max-width: 56px;
    max-height: 55px; } }

@-o-keyframes rewardPulse {
  from {
    max-width: 56px;
    max-height: 55px; }
  50% {
    width: 66px;
    max-width: 66px;
    max-height: 66px; }
  to {
    max-width: 56px;
    max-height: 55px; } }

@keyframes rewardPulse {
  from {
    max-width: 56px;
    max-height: 55px; }
  50% {
    width: 66px;
    max-width: 66px;
    max-height: 66px; }
  to {
    max-width: 56px;
    max-height: 55px; } }

/* line 235, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .xp .pulse {
  -webkit-animation: rewardPulse 0.15s infinite;
  animation: rewardPulse 0.15s infinite; }

/* line 238, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .gems .pulse {
  -webkit-animation: rewardPulse 0.25s infinite;
  animation: rewardPulse 0.25s infinite; }

/* line 244, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #lenovo-link {
  width: 100%;
  padding-top: 20px; }

/* line 248, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #sweepstakes-entry {
  height: 60px;
  display: inline-block; }

/* line 252, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #totals {
  width: 709px;
  height: 96px;
  background: transparent url(/images/pages/play/level/modal/xp_gems_parchment.png);
  position: relative;
  text-align: left; }
  /* line 259, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #totals .total-wrapper {
    position: absolute;
    top: 18px; }
    /* line 263, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #totals .total-wrapper#xp-wrapper {
      left: 117px;
      width: 300px; }
    /* line 267, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #totals .total-wrapper#gem-wrapper {
      left: 529px; }
      /* line 270, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal #totals .total-wrapper#gem-wrapper .total-label {
        width: 90px; }
    /* line 273, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #totals .total-wrapper .total-count {
      float: left;
      font-size: 45px;
      font-weight: bold;
      color: #282116;
      margin-right: 12px;
      width: 78px; }
      /* line 281, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal #totals .total-wrapper .total-count.four-digits {
        font-size: 40px;
        margin-top: 3px; }
      /* line 285, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal #totals .total-wrapper .total-count.five-digits {
        font-size: 30px;
        margin-top: 10px; }
    /* line 289, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #totals .total-wrapper .total-label {
      float: left;
      color: #675c4c;
      text-transform: uppercase;
      font-weight: bold;
      font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 18px;
      margin-top: 13px;
      line-height: 18px; }
    /* line 299, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #totals .total-wrapper .xp-bar-outer {
      background-color: #282116;
      border: 4px solid #282116;
      border-radius: 8px;
      width: 150px;
      height: 16px;
      margin-top: 3px;
      position: relative;
      float: left; }
      /* line 309, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal #totals .total-wrapper .xp-bar-outer .xp-bar-already-achieved {
        background-color: #a6d558;
        border-radius: 8px;
        height: 100%;
        position: absolute;
        z-index: 1; }
      /* line 317, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal #totals .total-wrapper .xp-bar-outer .xp-bar-total {
        background-color: #fdab2d;
        border: 1px solid #efb149;
        border-radius: 8px;
        height: 100%;
        position: absolute; }

/* line 324, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #share-level-container {
  width: 709px;
  height: 96px;
  background: transparent url(/images/pages/play/level/modal/share_level_parchment.png);
  position: relative;
  text-align: left;
  padding: 12px 20px 0 20px;
  text-align: center; }
  /* line 333, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #share-level-container .share-level-label {
    color: #675c4c;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    margin-top: 13px;
    line-height: 18px;
    text-align: center; }
  /* line 343, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #share-level-container #share-level-input {
    font-size: 12px;
    margin-top: 8px; }
  /* line 347, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #share-level-container #share-level-btn {
    width: 100%;
    margin-top: 7px; }

/* line 354, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .modal-footer {
  margin: 0 0 -80px 0;
  padding: 0 20px;
  text-align: center; }

/* line 360, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .sign-up-poke {
  width: 430px; }
  /* line 363, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .sign-up-poke.code-play {
    width: 495px; }
  /* line 366, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .sign-up-poke .sign-up-blurb {
    width: 175px;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    float: left;
    margin: 5px 0 0 5px;
    color: #a0967e; }
    /* line 378, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .sign-up-poke .sign-up-blurb.code-play {
      font-size: 14px;
      line-height: 14px; }
  /* line 382, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .sign-up-poke .sign-up-button {
    width: 250px;
    height: 60px;
    line-height: 30px;
    margin: 0;
    float: left; }
    /* line 389, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .sign-up-poke .sign-up-button.code-play {
      width: 315px; }
      /* line 392, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
      #hero-victory-modal .sign-up-poke .sign-up-button.code-play strong {
        font-size: 22px;
        color: #fcc935;
        opacity: 1;
        letter-spacing: -1px; }

/* line 398, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .subscribe-button {
  width: 209px;
  height: 60px;
  flex-grow: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 24.5px;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
  border-style: solid;
  border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2;
  background-color: black; }
  /* line 413, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .subscribe-button span {
    pointer-events: none; }
  /* line 416, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .subscribe-button:hover {
    border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
  /* line 419, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .subscribe-button:active {
    border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
    padding: 2px 0 0 2px;
    color: white; }

/* line 424, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .leaderboard-button, #hero-victory-modal .courses-button {
  height: 60px;
  line-height: 30px;
  margin: 0 10px;
  float: right; }

/* line 430, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .return-to-course-button {
  width: 258px;
  float: left; }

/* line 434, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .next-level-buttons {
  float: right; }
  /* line 437, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .next-level-buttons .next-level-button, #hero-victory-modal .next-level-buttons .return-to-ladder-button {
    width: 258px;
    height: 60px;
    line-height: 30px;
    margin: 0 10px; }
    /* line 443, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .next-level-buttons .next-level-button.code-play, #hero-victory-modal .next-level-buttons .return-to-ladder-button.code-play {
      width: 190px; }
  /* line 446, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .next-level-buttons .ladder-submission-view {
    display: inline-block;
    color: white; }
    /* line 450, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .next-level-buttons .ladder-submission-view .rank-button.btn-block {
      display: inline-block;
      width: auto;
      padding-left: 19px;
      padding-right: 19px; }
    /* line 456, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal .next-level-buttons .ladder-submission-view .last-submitted {
      float: none; }

/* line 459, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .hour-of-code-done {
  width: 424px;
  height: 80px;
  background: transparent url(/images/pages/play/level/modal/share_level_parchment.png);
  position: relative;
  text-align: left;
  padding: 12px 20px 0 20px;
  text-align: center; }
  /* line 468, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .hour-of-code-done .hoc-label {
    color: #675c4c;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase; }

/* line 475, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .offer {
  display: none; }
  /* line 478, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .offer img {
    margin: 5px 10px; }
  /* line 481, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal .offer p {
    color: white; }

/* line 484, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #victory-text {
  z-index: 1;
  text-align: center;
  padding: 30px 13px 0 13px;
  margin-bottom: 35px;
  font-size: 24px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }

/* line 496, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .level-title {
  z-index: 1;
  margin: 10px;
  font-size: 24px;
  color: #fcc935;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }

/* line 506, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal .level-name {
  z-index: 1;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }

/* line 516, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal #level-feedback {
  color: #fcc935;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 13px 20px 13px;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }
  /* line 525, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #level-feedback .rating {
    position: relative;
    margin-top: 5px;
    text-align: center;
    float: left;
    width: 50%; }
    /* line 532, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #level-feedback .rating .rating-label {
      margin-bottom: 8px; }
    /* line 535, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #level-feedback .rating i {
      cursor: pointer;
      padding: 5px;
      font-size: 48px;
      text-shadow: black 3px 3px 0, black -3px -3px 0, black 3px -3px 0, black -3px 3px 0, black 3px 0px 0, black 0px -3px 0, black -3px 0px 0, black 0px 3px 0; }
  /* line 541, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #level-feedback .review-label {
    margin-top: 5px;
    text-align: center;
    float: right;
    width: 50%; }
  /* line 547, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  #hero-victory-modal #level-feedback .review {
    width: 100%;
    text-align: center; }
    /* line 551, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    #hero-victory-modal #level-feedback .review textarea {
      float: right;
      width: 50%;
      height: 80px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.7);
      font-size: 16px; }

/* line 560, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal:not(.with-achievements) #totals {
  display: none; }

/* line 564, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
#hero-victory-modal.with-achievements #victory-text {
  display: none; }

/* line 569, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
html.no-borderimage #hero-victory-modal .modal-dialog {
  margin-top: 251px; }

/* line 571, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
html.no-borderimage #hero-victory-modal .background-wrapper {
  border: 0;
  background: url("/images/pages/play/level/modal/victory_modal_background.png");
  height: 713px; }

@media only screen and (max-height: 720px) {
  /* line 578, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  html.no-borderimage #hero-victory-modal.full-achievements .modal-dialog {
    margin-top: 175px; }
    /* line 580, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    html.no-borderimage #hero-victory-modal.full-achievements .modal-dialog #victory-header {
      background: transparent; } }

@media only screen and (max-height: 640px) {
  /* line 583, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  html.no-borderimage #hero-victory-modal.full-achievements .modal-dialog {
    margin-top: 121px; }
    /* line 585, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
    html.no-borderimage #hero-victory-modal.full-achievements .modal-dialog #victory-header {
      display: none; } }

/* line 592, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body.ipad #hero-victory-modal .xp .pulse, body.ipad #hero-victory-modal .gems .pulse {
  -webkit-animation: none;
  animation: none; }

/* line 596, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang='ru'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='es-ES'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='it'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='hu'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='mk-MK'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='ja'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='uk'] #hero-victory-modal #totals .total-wrapper .total-label {
  font-size: 12px; }

/* line 600, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang^='nl'] #hero-victory-modal #totals .total-wrapper .total-label, body[lang^='de'] #hero-victory-modal #totals .total-wrapper .total-label {
  font-size: 15px; }

/* line 605, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang^='es'] #hero-victory-modal .sign-up-poke.code-play .sign-up-blurb {
  font-size: 12px;
  letter-spacing: -1px; }

/* line 609, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang^='es'] #hero-victory-modal .sign-up-poke.code-play .sign-up-button {
  font-size: 16px; }

/* line 614, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang^='de'] #hero-victory-modal .sign-up-poke.code-play .sign-up-blurb {
  font-size: 12px; }

/* line 617, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
body[lang^='de'] #hero-victory-modal .sign-up-poke.code-play .sign-up-button {
  font-size: 14px;
  letter-spacing: 1px;
  white-space: normal;
  line-height: 16px; }
  /* line 623, /home/coco/codecombat/app/styles/play/level/modal/hero-victory-modal.sass */
  body[lang^='de'] #hero-victory-modal .sign-up-poke.code-play .sign-up-button strong {
    font-size: 28px;
    letter-spacing: 1px; }
