/* line 2, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
#campaign-editor-view #left-column {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 25%;
  margin-right: 1200px; }
  /* line 10, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
  #campaign-editor-view #left-column .treema-root {
    max-height: 100%;
    overflow: scroll; }
    /* line 14, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
    #campaign-editor-view #left-column .treema-root .completion {
      position: absolute;
      right: 0; }

/* line 18, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
#campaign-editor-view #right-column {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 75%; }
  /* line 27, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
  #campaign-editor-view #right-column #campaign-view .map .level:not(:hover) .banner[src="/images/pages/play/level-banner-unstarted.png"] {
    display: none; }
  /* line 29, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
  #campaign-editor-view #right-column #campaign-view .map .level:not(:hover) .banner[src="/images/pages/play/level-banner-unstarted-subscriber.png"] {
    display: none; }

/* line 32, /home/coco/codecombat/app/styles/editor/campaign/campaign-editor-view.sass */
#campaign-editor-view .patches-view {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 30; }
