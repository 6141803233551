/* line 3, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
.hints-view {
  position: relative;
  width: 500px;
  padding: 10px 20px;
  border-style: solid;
  border-image: url(/images/level/popover_border_background.png) 16 12 fill round;
  border-width: 16px 12px;
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  -o-user-select: initial;
  user-select: initial; }
  /* line 14, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view .close-hint-btn {
    position: absolute;
    right: 5px;
    top: 5px; }
    /* line 19, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
    .hints-view .close-hint-btn .glyphicon-remove {
      position: relative;
      top: 4px; }
  /* line 23, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view h1 {
    margin-bottom: 30px; }
  /* line 26, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view .btn-area {
    margin-top: 5px; }
  /* line 29, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view .hint-title {
    font-size: 18px;
    text-transform: uppercase; }
  /* line 33, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view .hint-body {
    height: 80%;
    overflow-y: auto; }
    /* line 36, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
    .hints-view .hint-body img {
      width: 100%; }
  /* line 39, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view .hint-pagination {
    font-size: 18px;
    margin-top: 0px;
    text-transform: uppercase; }
  /* line 44, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
  .hints-view pre {
    box-sizing: content-box;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none; }
    /* line 50, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
    .hints-view pre .ace_content .ace_marker-layer .ace_bracket {
      display: none; }
    /* line 52, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
    .hints-view pre .ace_content .ace_marker-layer .ace_selection {
      display: none; }
    /* line 55, /home/coco/codecombat/app/styles/play/level/hints-view.sass */
    .hints-view pre .ace_content .ace_cursor-layer .ace_cursor {
      display: none; }
