/* line 1, /home/coco/codecombat/app/styles/clans/clans.sass */
#clans-view {
  color: black; }
  /* line 4, /home/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .clan-title {
    cursor: pointer; }
  /* line 7, /home/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .create-clan-description {
    width: 50%; }
  /* line 10, /home/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .popover {
    max-width: 100%; }
    /* line 13, /home/coco/codecombat/app/styles/clans/clans.sass */
    #clans-view .popover h3 {
      background: transparent;
      border: 0;
      font-size: 30px;
      color: black; }
