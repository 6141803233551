/* line 3, /home/coco/codecombat/app/styles/editor/level/system/new.sass */
#editor-level-system-new-modal .loading {
  text-align: center; }

/* line 6, /home/coco/codecombat/app/styles/editor/level/system/new.sass */
#editor-level-system-new-modal .progress {
  margin: 0 20%;
  width: 60%; }

/* line 10, /home/coco/codecombat/app/styles/editor/level/system/new.sass */
#editor-level-system-new-modal .progress-bar {
  width: 100%; }
