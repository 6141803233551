/* line 2, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
#resource-hub-view .content {
  max-width: 900px;
  margin: auto; }
  /* line 6, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
  #resource-hub-view .content h4 {
    padding: 0 0 10px 0; }
  /* line 9, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
  #resource-hub-view .content ul {
    margin: 0 0 40px 0; }
    /* line 11, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
    #resource-hub-view .content ul p {
      font-size: .8em; }

/* line 14, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
#resource-hub-view .coming-soon {
  text-align: center;
  margin: 0 auto 0 auto;
  color: #666;
  margin: 30px 0 0 0; }

/* line 20, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
#resource-hub-view #hour-of-code {
  background-color: #cff2fc;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 0 30px 0;
  text-align: center; }
  /* line 28, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
  #resource-hub-view #hour-of-code p.small {
    font-style: italic; }
  /* line 31, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
  #resource-hub-view #hour-of-code ol {
    text-align: left;
    margin: 0 15px; }
    /* line 34, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
    #resource-hub-view #hour-of-code ol li {
      padding: 0 0 10px 0; }
  /* line 37, /home/coco/codecombat/app/styles/teachers/resource-hub-view.sass */
  #resource-hub-view #hour-of-code h3 {
    text-align: center;
    font-size: 1.5em; }
