/* line 2, /home/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
#skipped-contacts-view ol {
  padding: 0; }

/* line 5, /home/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
#skipped-contacts-view li.skipped-contact-info {
  list-style: none;
  border: thin solid grey;
  padding: 20px; }
  /* line 10, /home/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
  #skipped-contacts-view li.skipped-contact-info:nth-child(2n) {
    background-color: #EEEEEE; }
