/* line 6, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view .big-font {
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold; }

/* line 11, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view .one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 16, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view .nano-content {
  padding: 10px; }

/* line 19, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view #item-title {
  position: absolute;
  width: 228px;
  height: 50px;
  left: 910px;
  top: 60px;
  z-index: 2; }
  /* line 27, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-title h2 {
    font-size: 20px;
    margin: 12px 20px;
    text-align: center;
    color: #352819; }

/* line 33, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view #item-details-body {
  position: absolute;
  left: 860px;
  top: 126px;
  width: 353px;
  height: 449px; }
  /* line 40, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .nano-content {
    right: 24px; }
  /* line 45, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body #item-container {
    height: 163px;
    width: 100%; }
  /* line 49, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .item-img, #item-details-view #item-details-body .item-shadow {
    width: 130px;
    height: 130px; }
  /* line 53, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .item-img {
    top: 15px; }
  /* line 56, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .item-shadow {
    top: 25px;
    left: 5px;
    -webkit-filter: contrast(0%) brightness(0%);
    -moz-filter: contrast(0%) brightness(0%);
    -o-filter: contrast(0%) brightness(0%);
    filter: contrast(0%) brightness(0%);
    opacity: 0.2; }
  /* line 62, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body img.hr {
    width: 80%;
    margin: 0 10% -3px; }
    /* line 66, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body img.hr.faded {
      opacity: 0.4; }
  /* line 69, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .stat-row {
    height: 24px;
    position: relative;
    font-size: 20px;
    font-weight: bold; }
    /* line 75, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body .stat-row .stat-label {
      position: absolute;
      left: 54px;
      color: #5d4934; }
    /* line 80, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body .stat-row .stat {
      position: absolute;
      left: 150px;
      color: #2a261c; }
    /* line 85, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body .stat-row:not(.short-name) {
      text-align: center; }
      /* line 88, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
      #item-details-view #item-details-body .stat-row:not(.short-name) .stat-label, #item-details-view #item-details-body .stat-row:not(.short-name) .stat {
        position: static;
        display: inline-block; }
      /* line 92, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
      #item-details-view #item-details-body .stat-row:not(.short-name) .stat-label {
        margin-right: 8px; }
  /* line 95, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body .item-description {
    margin: 15px 15px 0 25px; }
  /* line 98, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #item-details-body #skills {
    margin: 15px 15px 0 25px; }
    /* line 101, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body #skills h3 {
      color: #292319; }
    /* line 104, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
    #item-details-view #item-details-body #skills strong {
      color: #32321e; }

/* line 107, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view #selected-item-unlock-button, #item-details-view .unequippable {
  left: 856px;
  top: 594px;
  width: 337px;
  height: 41px;
  font-size: 16px; }
  /* line 114, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
  #item-details-view #selected-item-unlock-button img, #item-details-view .unequippable img {
    height: 16px; }

/* line 117, /home/coco/codecombat/app/styles/play/modal/item-details-view.sass */
#item-details-view .unequippable {
  position: absolute; }
