/* line 7, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view #volume-group {
  position: relative;
  width: 100%;
  height: 25px;
  margin: 15px 0; }
  /* line 13, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group > * {
    position: absolute; }
  /* line 16, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .ui-slider {
    left: 40px;
    right: 40px;
    top: 4px;
    background-color: #f8a943;
    border: 4px solid #1a1512;
    height: 18px;
    border-radius: 18px; }
    /* line 25, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
    #options-view #volume-group .ui-slider .ui-slider-handle {
      background-color: #1a1512;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      top: -9px;
      outline: 0; }
  /* line 33, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon {
    font-size: 30px;
    top: -2px;
    color: #1a1512; }
  /* line 38, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon-volume-down {
    position: absolute;
    left: 0; }
  /* line 42, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon-volume-up {
    position: absolute;
    right: -10px; }

/* line 49, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .hr {
  width: 100%;
  height: 10px; }

/* line 53, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view h3 {
  margin-top: 5px;
  color: #1a1512; }

/* line 60, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group {
  font-size: 16px;
  color: #333333;
  padding-top: 4px;
  padding-left: 40px; }

/* line 66, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view label {
  font-weight: normal; }

/* line 73, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox label {
  position: relative; }

/* line 76, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox input {
  display: none; }
  /* line 80, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view .form-group.checkbox input + .custom-checkbox .glyphicon {
    display: none; }
  /* line 83, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view .form-group.checkbox input:checked + .custom-checkbox .glyphicon {
    display: inline;
    color: #f8a943;
    text-align: center;
    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black;
    font-size: 14px;
    position: relative;
    top: -1px; }

/* line 92, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox .custom-checkbox {
  border-radius: 2px;
  height: 27px;
  width: 27px;
  border: 4px solid #1a1512;
  position: absolute;
  background: #e4d9c4;
  text-align: center;
  left: -40px;
  top: -4px; }

/* line 108, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view select {
  border: 3px solid black;
  text-transform: uppercase; }

/* line 121, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view #player-avatar-container {
  position: relative;
  margin: 0 auto 40px;
  width: 230px;
  max-height: 230px;
  border-radius: 6px; }
  /* line 128, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #player-avatar-container img.profile-photo {
    width: 100%;
    border-radius: 6px; }
    /* line 132, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
    #options-view #player-avatar-container img.profile-photo.saving {
      opacity: 0.5; }
  /* line 135, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #player-avatar-container .profile-caption {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    text-align: center;
    border: 0;
    font-size: 18px; }
  /* line 148, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #player-avatar-container .editable-icon {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    color: #0b63bc;
    opacity: 0.5; }
  /* line 157, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #player-avatar-container:hover {
    cursor: pointer;
    box-shadow: 0px 0px 2px 1px #0b63bc; }
    /* line 161, /home/coco/codecombat/app/styles/play/menu/options-view.sass */
    #options-view #player-avatar-container:hover .editable-icon {
      opacity: 1.0;
      cursor: pointer; }
