/* line 6, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
#play-heroes-modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }
  /* line 11, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .modal-dialog {
    padding: 0;
    width: 820px;
    height: 658px; }
  /* line 19, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #play-heroes-background {
    position: absolute;
    top: -59px;
    left: -20px; }
  /* line 27, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal h1 {
    position: absolute;
    left: 154px;
    top: 25px;
    margin: 0;
    width: 450px;
    text-align: center;
    color: #febc44;
    font-size: 38px;
    text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0; }
  /* line 41, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #gems-count-container {
    position: absolute;
    right: 39px;
    top: 470px; }
    /* line 46, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #gems-count-container #gems-count {
      font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 24.5px;
      color: #5ecdfd; }
  /* line 54, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #close-modal {
    position: absolute;
    left: 615px;
    top: 17px;
    width: 60px;
    height: 60px;
    color: white;
    text-align: center;
    font-size: 30px;
    padding-top: 15px;
    cursor: pointer;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg); }
    /* line 67, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #close-modal:hover {
      color: yellow; }
  /* line 73, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel {
    width: 750px;
    height: 436px;
    position: absolute;
    left: 34px;
    top: 67px; }
    /* line 80, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .carousel-indicator-container {
      position: relative;
      z-index: 1; }
      /* line 84, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators {
        position: static;
        width: 100%;
        margin-left: 0;
        white-space: nowrap; }
        /* line 90, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator {
          width: 97px;
          height: 98px;
          margin: 0 -11px -20px -11px;
          position: relative;
          background: url(/images/pages/play/modal/hero-portrait.png);
          border: none;
          float: left; }
          /* line 99, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator:nth-child(15), #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator:nth-child(16) {
            float: right; }
          /* line 102, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.active {
            background: url(/images/pages/play/modal/hero-portrait-selected.png);
            z-index: 5; }
          /* line 106, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator .hero-avatar {
            width: 61px;
            height: 61px;
            background-size: contain;
            position: relative;
            left: 21px;
            top: 18px; }
          /* line 114, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.subscribe-unlock {
            background: url(/images/pages/play/modal/hero-portrait-silver.png);
            background-size: 100% 100%;
            z-index: 5; }
            /* line 119, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.subscribe-unlock.active {
              background: url(/images/pages/play/modal/hero-portrait-silver-selected.png);
              z-index: 6; }
            /* line 123, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.subscribe-unlock .hero-avatar {
              background-color: goldenrod;
              background-size: 100% 100%;
              width: 54px;
              height: 54px;
              left: 25px;
              top: 25px; }
          /* line 131, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.premium {
            background: url(/images/pages/play/modal/hero-portrait-gem.png);
            background-size: 100% 100%;
            z-index: 5; }
            /* line 136, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.premium.active {
              background: url(/images/pages/play/modal/hero-portrait-gem-selected.png);
              z-index: 6; }
            /* line 140, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.premium .hero-avatar {
              background-color: #0077a8;
              background-size: 100% 100%;
              width: 56px;
              height: 58px;
              left: 23px;
              top: 20px; }
              /* line 148, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
              #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.premium .hero-avatar.ie {
                opacity: 0.35;
                filter: alpha(opacity=35); }
            /* line 151, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.premium .lock-indicator {
              position: absolute;
              width: 40%;
              left: 30%;
              top: 30%;
              -webkit-filter: invert(90%);
              -moz-filter: invert(90%);
              -o-filter: invert(90%);
              filter: invert(90%); }
          /* line 159, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.purchasable .hero-avatar {
            background-color: goldenrod; }
          /* line 162, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
          #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.restricted {
            opacity: 0.25;
            filter: alpha(opacity=25); }
            /* line 164, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
            #play-heroes-modal #hero-carousel .carousel-indicator-container .carousel-indicators .hero-indicator.restricted .hero-avatar {
              background-color: black; }
    /* line 167, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .carousel-inner {
      position: absolute;
      top: 166px; }
  /* line 174, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-0 {
    transform: rotate(-5deg);
    z-index: 2; }
  /* line 178, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-1 {
    top: -3px;
    z-index: 1; }
  /* line 182, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-2 {
    top: -3px;
    transform: rotate(5deg);
    z-index: 1; }
  /* line 187, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-3 {
    transform: rotate(-1deg);
    z-index: 0; }
  /* line 191, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-4 {
    transform: rotate(3deg); }
  /* line 194, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-5 {
    z-index: 0; }
  /* line 197, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-6 {
    transform: rotate(6deg);
    top: -8px;
    z-index: 1; }
  /* line 202, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .hero-index-8 {
    transform: rotate(4deg); }
  /* line 211, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel .hero-item canvas, #play-heroes-modal #hero-carousel .hero-item .hero-pose-image {
    width: 334px;
    height: 275px;
    float: left; }
  /* line 216, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel .hero-item .hero-stats {
    width: 400px;
    height: 275px;
    float: left; }
  /* line 221, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel .hero-item .hero-pose-image {
    display: none;
    text-align: center; }
    /* line 225, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .hero-item .hero-pose-image img {
      height: 275px; }
  /* line 228, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel .hero-item .hero-stats {
    color: white;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text; }
    /* line 232, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .hero-item .hero-stats h2 {
      margin-top: 0px;
      color: white; }
    /* line 236, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-description {
      margin-bottom: 4px; }
    /* line 239, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row {
      margin: 3px 0; }
      /* line 242, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row .stat-label {
        float: left;
        width: 100px;
        color: #cbaa94; }
      /* line 247, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row .stat-value {
        display: inline-block;
        width: 280px;
        color: #f4bd44; }
      /* line 252, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row .stat-progress {
        background: #201b16;
        height: 15px;
        padding: 4px 5px;
        border-radius: 16px;
        position: relative;
        top: 2px;
        left: -3px;
        width: 70%; }
        /* line 262, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row .stat-progress .stat-progress-bar {
          height: 7px;
          border-radius: 7px; }
      /* line 267, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row.attack .stat-progress-bar {
        background: #c32424; }
      /* line 270, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row.health .stat-progress-bar {
        background: #0f802a; }
      /* line 273, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal #hero-carousel .hero-item .hero-stats .hero-stat-row.speed .stat-progress-bar {
        background: #4d52ab; }
  /* line 279, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel a.left, #play-heroes-modal #hero-carousel a.right {
    color: #4a3d33;
    position: absolute;
    top: 245px;
    width: 40px;
    height: 84px;
    font-size: 24px; }
    /* line 287, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel a.left .glyphicon, #play-heroes-modal #hero-carousel a.right .glyphicon {
      position: relative;
      top: 27px;
      left: 8px; }
    /* line 292, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel a.left:hover, #play-heroes-modal #hero-carousel a.left:active, #play-heroes-modal #hero-carousel a.right:hover, #play-heroes-modal #hero-carousel a.right:active {
      color: #7e6958; }
  /* line 295, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel a.right {
    right: -49px; }
  /* line 298, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-carousel a.left {
    left: -46px; }
    /* line 300, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #hero-carousel a.left .glyphicon {
      -webkit-transform: scaleX(-1) scaleY(1);
      -ms-transform: scaleX(-1) scaleY(1);
      transform: scaleX(-1) scaleY(1); }
  /* line 305, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #hero-footer {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text; }
  /* line 308, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #purchasable-hero-explanation, #play-heroes-modal #loading-hero-explanation, #play-heroes-modal #locked-hero-explanation {
    position: absolute;
    left: 32px;
    top: 532px;
    width: 541px;
    height: 97px;
    padding: 20px 40px; }
    /* line 316, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #purchasable-hero-explanation h2, #play-heroes-modal #loading-hero-explanation h2, #play-heroes-modal #locked-hero-explanation h2 {
      color: #333;
      text-align: center;
      margin: 0 auto; }
    /* line 321, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #purchasable-hero-explanation span.subscribers, #play-heroes-modal #loading-hero-explanation span.subscribers, #play-heroes-modal #locked-hero-explanation span.subscribers {
      text-align: center;
      display: table;
      margin: 0 auto; }
  /* line 326, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #restricted-hero-explanation {
    position: absolute;
    left: 32px;
    top: 527px;
    width: 541px;
    height: 102px;
    padding: 10px 40px;
    text-align: center; }
    /* line 335, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #restricted-hero-explanation h2 {
      color: #333;
      margin-top: 12px;
      margin-bottom: 5px; }
  /* line 340, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #restricted-hero-button, #play-heroes-modal #purchase-hero-button {
    width: 209px;
    height: 110px;
    position: absolute;
    left: 588px;
    top: 522px;
    line-height: 100px;
    text-align: center;
    text-transform: uppercase;
    font-size: 24.5px;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: white; }
  /* line 353, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #subscribe-hero-button {
    width: 209px;
    height: 110px;
    position: absolute;
    left: 588px;
    top: 522px;
    flex-grow: 1;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    border-style: solid;
    border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
    border-width: 14px 20px 20px 20px;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #f2f2f2;
    background-color: black; }
    /* line 372, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #subscribe-hero-button span {
      pointer-events: none; }
    /* line 375, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #subscribe-hero-button:hover {
      border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
    /* line 378, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #subscribe-hero-button:active {
      border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
      padding: 2px 0 0 2px;
      color: white; }
  /* line 383, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #purchase-hero-button {
    border: 3px solid #074153;
    background: #0077a8;
    border-radius: 0; }
    /* line 388, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #purchase-hero-button:disabled {
      background: #486a71;
      opacity: 1;
      color: rgba(255, 255, 255, 0.4); }
    /* line 393, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #purchase-hero-button > * {
      opacity: 0.9;
      filter: alpha(opacity=90); }
    /* line 396, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal #purchase-hero-button:hover > * {
      opacity: 1;
      filter: alpha(opacity=100); }
  /* line 403, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal .form {
    position: absolute;
    left: 32px;
    top: 527px;
    width: 541px;
    height: 102px;
    padding: 10px 40px; }
    /* line 411, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal .form .help-block {
      color: #333333;
      font-size: 14px;
      font-weight: bold; }
    /* line 416, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal .form select {
      font-size: 18px; }
    /* line 419, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
    #play-heroes-modal .form .fancy-select {
      display: inline-block;
      width: 100%; }
      /* line 423, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal .form .fancy-select .options {
        text-transform: none; }
      /* line 426, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal .form .fancy-select .trigger, #play-heroes-modal .form .fancy-select .options {
        background-color: #efe8d9;
        color: black; }
      /* line 430, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal .form .fancy-select .trigger {
        text-transform: uppercase;
        border: 3px solid black;
        font-size: 16px;
        padding: 5px 10px;
        width: 100%; }
        /* line 438, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .trigger:after {
          border: 8px solid transparent;
          border-top-color: black;
          top: 13px;
          right: 11px; }
      /* line 444, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal .form .fancy-select .options {
        padding-left: 5px;
        overflow: visible; }
        /* line 448, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options .selected {
          color: black; }
        /* line 451, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options .hover {
          color: black;
          background-color: #abc; }
      /* line 456, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
      #play-heroes-modal .form .fancy-select .options li {
        padding-left: 40px;
        background: transparent url(/images/common/code_languages/javascript_small.png) no-repeat left center;
        background-size: 32px 32px;
        margin-bottom: -1px; }
        /* line 462, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="python"] {
          background-image: url(/images/common/code_languages/python_small.png); }
        /* line 464, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="coffeescript"] {
          background-image: url(/images/common/code_languages/coffeescript_small.png); }
        /* line 466, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="clojure"] {
          background-image: url(/images/common/code_languages/clojure_small.png); }
        /* line 468, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="lua"] {
          background-image: url(/images/common/code_languages/lua_small.png); }
        /* line 470, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="java"] {
          background-image: url(/images/common/code_languages/java_small.png); }
        /* line 472, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
        #play-heroes-modal .form .fancy-select .options li[data-value="io"] {
          background-image: url(/images/common/code_languages/io_small.png); }
  /* line 475, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #confirm-button {
    background: url(/images/pages/play/modal/confirm-button.png);
    width: 209px;
    height: 110px;
    position: absolute;
    left: 588px;
    top: 522px;
    padding: 36px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: white; }
  /* line 489, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
  #play-heroes-modal #codeplay-choose-hero-ad {
    width: 100%;
    position: absolute;
    top: 670px; }

/* line 496, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
body.ipad #play-heroes-modal .form {
  display: none; }

/* line 501, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
body[lang='ru'] #play-heroes-modal #hero-carousel .hero-item .hero-stats h2 {
  font-size: 24px; }

/* line 504, /home/coco/codecombat/app/styles/play/modal/play-heroes-modal.sass */
body[lang='ru'] #play-heroes-modal #purchase-hero-button {
  font-size: 18px; }
