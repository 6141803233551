/* line 2, /home/coco/codecombat/app/styles/play/common/ladder_submission.sass */
.ladder-submission-view button {
  text-shadow: 0px -1px 0px black; }

/* line 5, /home/coco/codecombat/app/styles/play/common/ladder_submission.sass */
.ladder-submission-view .last-submitted, .ladder-submission-view .help-simulate {
  font-size: 14px;
  font-weight: normal; }

/* line 9, /home/coco/codecombat/app/styles/play/common/ladder_submission.sass */
.ladder-submission-view .last-submitted {
  float: left; }

/* line 12, /home/coco/codecombat/app/styles/play/common/ladder_submission.sass */
.ladder-submission-view .help-simulate {
  float: right; }
