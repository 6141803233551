/* line 2, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
#outcomes-report-view textarea {
  width: 100%;
  height: 20em; }

/* line 6, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
#admin-outcomes-report-result-view {
  font-family: 'Open Sans', sans-serif;
  margin-left: auto;
  margin-right: auto;
  width: 8.5in;
  background-color: white; }
  /* line 12, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .clear-both {
    clear: both; }
  /* line 15, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .menu {
    position: fixed;
    left: 5px;
    top: 5px; }
    @media print {
      /* line 15, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .menu {
        display: none; } }
  /* line 23, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .header-art {
    float: right;
    width: 2in;
    margin-top: 0.35in;
    margin-right: 0.4in;
    transform: scaleX(-1); }
  /* line 30, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .header {
    height: 2.15in;
    padding-top: 0.1in;
    padding-left: 0.25in;
    padding-right: 0.25in;
    background-color: #0e4c60;
    -webkit-print-color-adjust: exact !important;
    background: linear-gradient(#0e4c60, #0e4c60) !important; }
    /* line 39, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .header h4, #admin-outcomes-report-result-view .header h5 {
      padding-left: 0.25in;
      bottom: 0px;
      color: white !important; }
    /* line 44, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .header h4 {
      font-size: 24pt;
      font-family: 'Arvo', serif; }
    /* line 48, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .header h5 {
      font-family: 'Open Sans', sans-serif;
      font-size: 15pt;
      font-weight: 600; }
    /* line 54, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .header .print-logo {
      margin: 0 0 0 0;
      width: 3.75in; }
  /* line 58, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .address {
    margin-top: 0.25in;
    padding-left: 0.5in;
    padding-right: 0.5in;
    text-align: left; }
    /* line 63, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .address p {
      line-height: 18pt;
      font-size: 15pt;
      margin-bottom: 0.1in; }
  /* line 69, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .course {
    margin-bottom: 0.25in; }
    /* line 71, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .course .bar {
      margin-bottom: 0.0in;
      page-break-inside: avoid; }
      /* line 75, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .course .bar .concepts-list {
        max-width: 30%; }
      /* line 77, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .course .bar .el {
        margin-right: 0.25in;
        height: 1in;
        float: left; }
        /* line 79, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
        #admin-outcomes-report-result-view .course .bar .el svg {
          transform: rotate(-90deg); }
          /* line 81, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
          #admin-outcomes-report-result-view .course .bar .el svg circle.top {
            fill: #f2be18;
            stroke-width: 50;
            stroke: #0e4b60; }
          /* line 85, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
          #admin-outcomes-report-result-view .course .bar .el svg circle.bottom {
            fill: #f2be18; }
        /* line 89, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
        #admin-outcomes-report-result-view .course .bar .el .big {
          display: block;
          font-size: 41pt;
          line-height: 41pt;
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          text-align: center;
          width: 1.5in; }
        /* line 97, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
        #admin-outcomes-report-result-view .course .bar .el .under {
          display: block;
          text-align: center;
          width: 1.5in; }
        /* line 101, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
        #admin-outcomes-report-result-view .course .bar .el li {
          line-height: 14pt;
          font-size: 12pt; }
    /* line 107, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .course .bar::after {
      clear: both;
      display: table;
      content: " "; }
    /* line 111, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .course h3 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 18pt; }
  /* line 116, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .block {
    margin-top: 0;
    margin-left: 0.5in;
    margin-right: 0.5in; }
    /* line 121, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block h1, #admin-outcomes-report-result-view .block h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 22pt;
      font-weight: 100;
      margin-top: 0.5in;
      border-bottom: 1px solid #ccc;
      border-radius: 0px;
      margin-bottom: 0.1in; }
    /* line 130, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block h3, #admin-outcomes-report-result-view .block h4 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600; }
    /* line 134, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block h1 {
      font-size: 18pt;
      line-height: 18pt; }
    /* line 138, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block h2 {
      font-size: 14pt;
      line-height: 15pt; }
    /* line 142, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block h4 {
      font-size: 18pt;
      line-height: 18pt;
      font-weight: 600; }
    /* line 147, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block div.fakebar {
      margin-top: 0.1in;
      margin-bottom: 0.25in;
      background-color: #1f572b;
      -webkit-print-color-adjust: exact !important;
      background: linear-gradient(#1f572b, #1f572b) !important;
      height: 0.4in;
      line-height: 0.4in; }
      /* line 158, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .block div.fakebar > div {
        background-color: white;
        -webkit-print-color-adjust: exact !important;
        background: linear-gradient(white, white) !important;
        height: 100%;
        padding-left: 0.1in;
        float: right;
        font-size: 32pt;
        font-weight: 600; }
        /* line 167, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
        #admin-outcomes-report-result-view .block div.fakebar > div small {
          font-size: 18pt;
          font-weight: 600; }
      /* line 170, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .block div.fakebar > div::after {
        clear: both;
        content: ' ';
        display: table; }
    /* line 175, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block p {
      font-size: 14pt;
      line-height: 18pt; }
    /* line 180, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block .left-col {
      page-break-inside: avoid;
      float: left;
      width: 4in;
      margin-right: 1in; }
    /* line 186, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block .right-col {
      page-break-inside: avoid;
      float: left;
      width: 2.5in;
      font-size: 14pt;
      line-height: 18pt; }
      /* line 192, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
      #admin-outcomes-report-result-view .block .right-col ul {
        padding-left: 2ex; }
    /* line 195, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block .row-row {
      page-break-inside: avoid; }
    /* line 198, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .block .rob-row::after {
      page-break-inside: avoid;
      content: " ";
      display: table;
      clear: both; }
  /* line 204, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view div.dont-break {
    page-break-inside: avoid; }
  /* line 207, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view img.anya {
    margin-left: 0.5in;
    margin-top: 0.5in;
    float: left;
    width: 1.75in; }
  /* line 213, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .room-for-anya {
    margin-left: 2.5in; }
  /* line 216, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
  #admin-outcomes-report-result-view .bottom {
    border-top: 2px solid black;
    padding-top: 0.1in;
    margin-top: 0.25in;
    padding-bottom: 0.25in;
    margin-left: 0.25in;
    margin-right: 0.25in; }
    /* line 223, /home/coco/codecombat/app/styles/admin/admin-outcomes-report.sass */
    #admin-outcomes-report-result-view .bottom p {
      text-align: center;
      line-height: 15pt;
      font-weight: 600; }
