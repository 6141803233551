/* line 4, /home/coco/codecombat/app/styles/play/level/chat.sass */
#level-chat-view {
  box-sizing: border-box;
  width: 22%;
  position: relative;
  left: 10px;
  bottom: 10px;
  height: 0;
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px black; }
  /* line 17, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view .action {
    color: #aff; }
  /* line 19, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view tr.me .action {
    color: #ffa; }
  /* line 22, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view .closed-chat-area {
    margin-left: 20px;
    margin-bottom: 25px;
    margin-right: 20px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 280px; }
  /* line 31, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view .open-chat-area {
    background-color: rgba(50, 50, 50, 0.6);
    padding: 5px 20px 5px 19px;
    border-radius: 5px;
    max-height: 200px;
    overflow-x: scroll;
    border: 1px solid #222;
    position: absolute;
    bottom: 30px;
    left: 20px;
    width: 239px; }
  /* line 43, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view tr {
    line-height: 11px;
    height: 16px; }
    /* line 45, /home/coco/codecombat/app/styles/play/level/chat.sass */
    #level-chat-view tr strong {
      color: #aff; }
    /* line 47, /home/coco/codecombat/app/styles/play/level/chat.sass */
    #level-chat-view tr.me strong {
      color: #ffa; }
  /* line 51, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view i {
    position: absolute;
    bottom: 5px;
    left: 3px;
    float: left;
    cursor: pointer; }
  /* line 58, /home/coco/codecombat/app/styles/play/level/chat.sass */
  #level-chat-view input, #level-chat-view textarea {
    position: absolute;
    bottom: 0px;
    left: 20px;
    right: 0px;
    width: 280px;
    height: 22px;
    box-sizing: border-box;
    font-size: 12px;
    color: white;
    background-color: rgba(50, 50, 50, 0.4);
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    border-width: 1px;
    text-shadow: 1px 1px 1px black; }
    /* line 74, /home/coco/codecombat/app/styles/play/level/chat.sass */
    #level-chat-view input:focus, #level-chat-view textarea:focus {
      background-color: #323232; }
