/* line 5, /home/coco/codecombat/app/styles/account/main-account-view.sass */
#main-account-view #account-links {
  width: 300px; }

/* line 8, /home/coco/codecombat/app/styles/account/main-account-view.sass */
#main-account-view #account-links .btn {
  width: 100%;
  padding: 35px;
  margin-bottom: 10%;
  font-size: 25px;
  background-image: linear-gradient(to bottom, rgba(181, 169, 125, 0), #b5a97d);
  font-weight: 600; }

/* line 16, /home/coco/codecombat/app/styles/account/main-account-view.sass */
#main-account-view .logout-btn {
  font-weight: bold; }

/* line 19, /home/coco/codecombat/app/styles/account/main-account-view.sass */
#main-account-view .row-top-buffer {
  margin-top: 5%; }

/* line 22, /home/coco/codecombat/app/styles/account/main-account-view.sass */
#main-account-view .row-bot-buffer {
  margin-bottom: 7%; }
