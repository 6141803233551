/* line 3, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .invalid-email-message {
  color: red;
  font-size: 12px; }

/* line 7, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .recipient-emails.invalid {
  border: 1px solid red; }

/* line 10, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .logged-out-blurb {
  font-size: 18px; }

/* line 13, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .start-subscription-button, #subscription-view .end-subscription-button {
  margin-bottom: 20px;
  float: left;
  width: 100%; }

/* line 18, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .unsubscribe-feedback {
  width: 100%; }
  /* line 21, /home/coco/codecombat/app/styles/account/subscription-view.sass */
  #subscription-view .unsubscribe-feedback h3 {
    margin-top: 0; }
  /* line 24, /home/coco/codecombat/app/styles/account/subscription-view.sass */
  #subscription-view .unsubscribe-feedback textarea {
    width: 100%; }
  /* line 27, /home/coco/codecombat/app/styles/account/subscription-view.sass */
  #subscription-view .unsubscribe-feedback button.btn {
    width: 100%;
    margin-top: 12px; }

/* line 33, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .recipient-emails {
  min-width: 50%; }

/* line 36, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .recipients-subscribe-button {
  margin-top: 10px; }

/* line 39, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .recipient-unsubscribe-button {
  width: auto; }

/* line 42, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .confirm-recipient-unsubscribe-button {
  width: auto; }

/* line 45, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .discount-table {
  width: 50%; }

/* line 48, /home/coco/codecombat/app/styles/account/subscription-view.sass */
#subscription-view .recipients-table {
  width: 50%; }
  /* line 50, /home/coco/codecombat/app/styles/account/subscription-view.sass */
  #subscription-view .recipients-table .recipient-unsubscribe {
    text-align: right; }
