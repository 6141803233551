/* line 2, /home/coco/codecombat/app/styles/editor/article/edit.sass */
#editor-article-edit-view .treema-root {
  margin-bottom: 20px; }

/* line 5, /home/coco/codecombat/app/styles/editor/article/edit.sass */
#editor-article-edit-view .article-tool-button {
  float: right;
  margin-bottom: 15px;
  margin-left: 10px; }

/* line 10, /home/coco/codecombat/app/styles/editor/article/edit.sass */
#editor-article-edit-view textarea {
  width: 92%;
  height: 300px; }
