/* line 2, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view .nav-tabs, #settings-treema .treema-markdown .nav-tabs {
  height: 41px; }

/* line 5, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view .tab-content, #settings-treema .treema-markdown .tab-content {
  padding-top: 20px;
  margin-bottom: 50px; }

/* line 9, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view li:not(.active) a[data-toggle="tab"], #settings-treema .treema-markdown li:not(.active) a[data-toggle="tab"] {
  cursor: pointer; }

/* line 12, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view img, #settings-treema .treema-markdown img {
  display: block;
  margin: 0 auto; }

/* line 16, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view img + em, #settings-treema .treema-markdown img + em {
  display: block;
  margin: 0 auto;
  text-align: center; }

/* line 21, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view hr, #settings-treema .treema-markdown hr {
  border-color: #5c5c5c;
  width: 80%; }

/* line 25, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view table, #settings-treema .treema-markdown table {
  width: 80%;
  margin: 20px 10%; }

/* line 31, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view .purchase-button, #settings-treema .treema-markdown .purchase-button {
  position: absolute;
  left: 73px;
  width: 600px;
  height: 70px;
  top: 430px;
  font-size: 32px;
  line-height: 42px;
  border-style: solid;
  border-image: url(/images/level/code_toolbar_submit_button_active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 44, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
  #guide-view .purchase-button span, #settings-treema .treema-markdown .purchase-button span {
    pointer-events: none; }
  /* line 47, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
  #guide-view .purchase-button:hover, #settings-treema .treema-markdown .purchase-button:hover {
    border-image: url(/images/level/code_toolbar_submit_button_zazz.png) 14 20 20 20 fill round;
    color: white; }
  /* line 51, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
  #guide-view .purchase-button:active, #settings-treema .treema-markdown .purchase-button:active {
    border-image: url(/images/level/code_toolbar_submit_button_zazz_pressed.png) 14 20 20 20 fill round;
    padding: 2px 0 0 2px;
    color: white; }

/* line 58, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view.has-tabs {
  margin-top: -40px; }

/* line 61, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view pre.ace_editor {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #f9f2f4;
  font-size: 12px;
  font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important; }
  /* line 68, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
  #guide-view pre.ace_editor .ace_cursor, #guide-view pre.ace_editor .ace_bracket {
    display: none; }

/* line 72, /home/coco/codecombat/app/styles/play/menu/guide-view.sass */
#guide-view .tab-content img {
  max-width: 100%; }
