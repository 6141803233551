/* line 3, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view hr {
  margin-top: 5px;
  margin-bottom: 10px; }

/* line 8, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view #main-button-area .btn {
  margin-left: 10px; }

/* line 11, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view #student-stats-row {
  margin-bottom: 10px; }

/* line 14, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .student-name {
  font-size: 20px;
  font-weight: bold; }

/* line 18, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .progress {
  margin-bottom: 2px; }

/* line 21, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .progress-bar {
  border-right: 1px solid grey; }

/* line 24, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .progress-bar-complete {
  background-color: #9DE257;
  color: grey; }

/* line 27, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .progress-bar-started {
  background-color: #FFF6A1;
  color: grey; }

/* line 30, /home/coco/codecombat/app/styles/courses/classroom-view.sass */
#classroom-view .progress-bar-default {
  background-color: white;
  color: grey; }
