/* line 3, /home/coco/codecombat/app/styles/modal/create-account-modal/confirmation-view.sass */
#confirmation-view {
  text-align: left; }
  /* line 6, /home/coco/codecombat/app/styles/modal/create-account-modal/confirmation-view.sass */
  #confirmation-view .confirm_container {
    width: 100%; }
  /* line 9, /home/coco/codecombat/app/styles/modal/create-account-modal/confirmation-view.sass */
  #confirmation-view .signup-info-box-wrapper {
    width: 100%; }
  /* line 12, /home/coco/codecombat/app/styles/modal/create-account-modal/confirmation-view.sass */
  #confirmation-view .signup-info-box {
    padding: 10px 20px;
    border: 2px dashed #7D0101; }
  /* line 16, /home/coco/codecombat/app/styles/modal/create-account-modal/confirmation-view.sass */
  #confirmation-view .modal-body-content {
    width: 80%;
    margin-left: 10%; }
