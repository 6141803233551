/* line 6, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .modal-dialog {
  margin: 60px auto 0 auto;
  padding: 0;
  width: 746px;
  height: 520px;
  background: none; }

/* line 13, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .modal-content {
  box-shadow: none;
  height: 520px; }

/* line 18, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal #subscribe-background {
  position: absolute;
  top: -61px;
  left: 0px; }

/* line 25, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal h1 {
  position: absolute;
  left: 170px;
  top: 25px;
  margin: 0;
  width: 410px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0, black 6px 6px 6px;
  font-variant: normal;
  text-transform: uppercase; }

/* line 40, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal #close-modal {
  position: absolute;
  left: 568px;
  top: 17px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 53, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal #close-modal:hover {
    color: yellow; }

/* line 56, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .subscribe-image {
  position: absolute;
  top: 114px;
  right: 65px; }

/* line 62, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .sub {
  position: absolute;
  right: -20px;
  bottom: -32px;
  z-index: 1;
  width: 256px; }
  /* line 69, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .sub button {
    width: 256px; }

/* line 72, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .wiggle {
  animation: excited-wiggle 0.25s 2 alternate; }

@keyframes excited-wiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(-2deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(2deg); }
  100% {
    transform: rotate(0deg); } }

/* line 87, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .excitable-hidden:hover {
  animation: excitable-anim-hidden 1s infinite alternate; }

@keyframes excitable-anim-hidden {
  from {
    transform: scale(1) rotate(-5deg);
    filter: brightness(-75%) drop-shadow(0px 0px 0px black);
    -webkit-filter: brightness(0.25) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.65)); }
  to {
    transform: scale(1.1) rotate(5deg) translateY(-16px);
    filter: brightness(-75%) drop-shadow(4px 8px 0px rgba(0, 0, 0, 0.65));
    -webkit-filter: brightness(0.25) drop-shadow(4px 8px 0px rgba(0, 0, 0, 0.65)); } }

/* line 100, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .no-show-pet {
  filter: brightness(-75%);
  -webkit-filter: brightness(0.25); }

/* line 104, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .excitable:hover {
  animation: excitable-anim 1s infinite alternate; }

@keyframes excitable-anim {
  from {
    transform: scale(1) rotate(-5deg);
    filter: drop-shadow(0px 0px 0px black); }
  to {
    transform: scale(1.1) rotate(5deg) translateY(-16px);
    filter: drop-shadow(4px 8px 0px rgba(0, 0, 0, 0.65)); } }

/* line 115, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal #item-tooltip {
  border-style: solid;
  border-image: url(/images/level/popover_border_background.png) 16 12 fill stretch;
  border-width: 16px 12px;
  z-index: 2; }

/* line 121, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .paper-area {
  position: absolute;
  top: 97px;
  left: 27px;
  height: 410px;
  width: 691px;
  -webkit-clip-path: url(#clipper);
  clip-path: url(#clipper); }
  /* line 130, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .paper-area .full-width-image {
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    pointer-events: none; }
  /* line 136, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .paper-area img {
    position: absolute; }
  /* line 138, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .paper-area .row {
    margin-bottom: 17px; }
  /* line 141, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .paper-area .nfo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 350px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    color: white;
    font-size: large; }

/* line 154, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal li a {
  color: #7f7fff; }

/* line 160, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .subscribe-actions {
  position: absolute;
  top: 430px;
  left: 18px;
  right: 18px;
  display: flex; }
  /* line 167, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .subscribe-actions > button {
    height: 100px;
    margin: 0 5px; }

/* line 173, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .purchase-button {
  flex-grow: 1;
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 182, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .purchase-button span {
    pointer-events: none; }
  /* line 185, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .purchase-button:hover {
    border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
  /* line 188, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .purchase-button:active {
    border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
    padding: 2px 0 0 2px;
    color: white; }

/* line 195, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
#announcement-modal .sale-button, #announcement-modal .lifetime-button {
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-success-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 200, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .sale-button:hover, #announcement-modal .lifetime-button:hover {
    border-image: url(/images/common/button-background-success-inactive.png) 14 20 20 20 fill round; }
  /* line 202, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .sale-button:active, #announcement-modal .lifetime-button:active {
    border-image: url(/images/common/button-background-success-pressed.png) 14 20 20 20 fill round; }
  /* line 207, /home/coco/codecombat/app/styles/play/modal/announcement-modal.sass */
  #announcement-modal .sale-button span, #announcement-modal .lifetime-button span {
    pointer-events: none; }
