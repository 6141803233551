/* line 5, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
#level-dialogue-view {
  width: 417px;
  height: 296px;
  background: transparent url(/images/level/code_palette_wood_background.png);
  background-size: 100% auto;
  position: absolute;
  bottom: -256px;
  left: 300px;
  left: calc(55% - 417px - 200px);
  -webkit-transition: 1s cubic-bezier(0.17, 0.89, 0.42, 1.36);
  transition: 1s cubic-bezier(0.17, 0.89, 0.42, 1.36);
  z-index: 1; }

@-webkit-keyframes speakingPulse {
  from {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; }
  50% {
    filter: drop-shadow(0px 0px 35px skyblue);
    color: skyblue; }
  to {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; } }

@-moz-keyframes speakingPulse {
  from {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; }
  50% {
    filter: drop-shadow(0px 0px 35px skyblue);
    color: skyblue; }
  to {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; } }

@-ms-keyframes speakingPulse {
  /* line 7, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view from {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; }
  /* line 10, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view 50% {
    filter: drop-shadow(0px 0px 35px skyblue);
    color: skyblue; }
  /* line 13, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view to {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; } }

@-o-keyframes speakingPulse {
  from {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; }
  50% {
    filter: drop-shadow(0px 0px 35px skyblue);
    color: skyblue; }
  to {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; } }

@keyframes speakingPulse {
  from {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; }
  50% {
    filter: drop-shadow(0px 0px 35px skyblue);
    color: skyblue; }
  to {
    filter: drop-shadow(0px 0px 8px #333);
    color: white; } }
  /* line 29, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view.active {
    display: block;
    bottom: -20px; }
  /* line 33, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view.debrief {
    bottom: -300px; }
  /* line 36, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view.active.debrief {
    bottom: -65px; }
  /* line 41, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view.speaking .dialogue-area .bubble {
    -webkit-animation: speakingPulse 1.5s infinite;
    animation: speakingPulse 1.5s infinite; }
  /* line 44, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view .dialogue-area {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1; }
    /* line 50, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
    #level-dialogue-view .dialogue-area .bubble {
      position: relative;
      margin: 10px;
      padding: 20px 20px 40px 20px;
      color: white;
      font-weight: bold;
      background: transparent url(/images/level/dialogue_background.png);
      background-size: 100% 100%;
      border: black solid 1px;
      border-radius: 10px;
      font-size: 18px;
      line-height: 20px; }
      /* line 63, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble strong, #level-dialogue-view .dialogue-area .bubble a {
        color: #FFCCAA; }
      /* line 66, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble a {
        text-decoration: underline; }
      /* line 69, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble .hud-hint {
        font-weight: normal;
        color: #ddd;
        font-size: 14px;
        line-height: 16px;
        vertical-align: middle; }
      /* line 76, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble .enter {
        position: absolute;
        right: 15px;
        bottom: 20px; }
        /* line 80, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
        #level-dialogue-view .dialogue-area .bubble .enter div.dot {
          background: #337;
          width: 8px;
          height: 8px;
          position: absolute;
          right: 8px;
          top: 9px;
          border-radius: 5px; }
      /* line 89, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble button, #level-dialogue-view .dialogue-area .bubble .alert {
        padding: 2px 5px; }
      /* line 92, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble .enter button.with-dot {
        padding-right: 20px; }
      /* line 95, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble h3 {
        margin: 0;
        font-size: 16px;
        line-height: 16px;
        color: #338; }
      /* line 101, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view .dialogue-area .bubble button {
        margin-left: 10px; }
  /* line 104, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  #level-dialogue-view.debrief {
    z-index: 11;
    width: 100vw;
    max-width: 1024px;
    position: absolute;
    left: 50vw;
    transform: translateX(-50%); }
    /* line 111, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
    #level-dialogue-view.debrief img:not(.embiggen) {
      position: absolute;
      width: 20%;
      bottom: 25%;
      filter: drop-shadow(8px 8px 8px black);
      left: 2.5%; }
    /* line 117, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
    #level-dialogue-view.debrief .embiggen {
      position: absolute;
      left: 0;
      width: 25%;
      bottom: 0;
      filter: drop-shadow(8px 8px 8px black); }
    /* line 123, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
    #level-dialogue-view.debrief .bubble {
      border: initial;
      width: 75%;
      height: 66.66%;
      right: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-66.66%);
      padding: 64px !important; }
    @media (max-width: 800px) {
      /* line 104, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
      #level-dialogue-view.debrief {
        height: 50%; }
        /* line 134, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
        #level-dialogue-view.debrief .bubble > p {
          margin-top: -32px; } }

/* line 137, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
body.ipad #level-dialogue-view {
  left: auto;
  right: 0; }
  /* line 141, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  body.ipad #level-dialogue-view.active {
    bottom: -60px; }
  /* line 144, /home/coco/codecombat/app/styles/play/level/level-dialogue-view.sass */
  body.ipad #level-dialogue-view .hud-hint {
    visibility: hidden; }
