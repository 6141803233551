/* line 2, /home/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view #thangTable {
  width: 100%; }

/* line 5, /home/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .tasksTable {
  width: 100%; }

/* line 8, /home/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .tasks {
  width: 87.5%; }

/* line 11, /home/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .taskOwner {
  width: 12.5%; }
