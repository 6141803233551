/* line 2, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
#systems-tab-view h3 {
  margin-top: 0; }
  @media screen and (max-width: 800px) {
    /* line 2, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view h3 {
      display: none; } }

/* line 7, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
#systems-tab-view .toggle {
  padding: 6px 8px;
  z-index: 11;
  margin-top: 0px;
  margin-left: 2px; }

/* line 13, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
#systems-tab-view .systems-container {
  position: absolute;
  top: 0;
  bottom: 40px; }
  /* line 18, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view .systems-container .editor-nano-container {
    position: relative;
    height: 90%;
    width: 250px; }
  /* line 23, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view .systems-container .treema-root {
    position: absolute;
    bottom: 0;
    width: 250px;
    overflow: scroll; }
    @media screen and (max-width: 800px) {
      /* line 23, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
      #systems-tab-view .systems-container .treema-root {
        z-index: 10;
        bottom: -35px; } }
    /* line 32, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view .systems-container .treema-root > .treema-children > .treema-add-child {
      display: none; }
    /* line 35, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view .systems-container .treema-root .treema-children .treema-row * {
      cursor: pointer !important; }

/* line 38, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
#systems-tab-view #add-system-button {
  position: absolute;
  bottom: 0;
  left: 170px;
  top: auto; }
  @media screen and (max-width: 800px) {
    /* line 38, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view #add-system-button {
      left: 40px;
      top: 1px;
      bottom: auto;
      padding: 8px 10px; } }
  /* line 49, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view #add-system-button .text {
    display: block; }
    @media screen and (max-width: 800px) {
      /* line 49, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
      #systems-tab-view #add-system-button .text {
        display: none; } }
  /* line 53, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view #add-system-button [class^='icon-'] {
    display: none; }
    @media screen and (max-width: 800px) {
      /* line 53, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
      #systems-tab-view #add-system-button [class^='icon-'] {
        display: block; } }

/* line 58, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
#systems-tab-view .edit-system-container {
  margin-left: 290px;
  position: absolute;
  right: 0;
  left: 0px;
  top: 0;
  bottom: 0; }
  @media screen and (max-width: 800px) {
    /* line 58, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view .edit-system-container {
      margin-left: 0px; }
      /* line 68, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
      #systems-tab-view .edit-system-container .nav-tabs {
        margin-left: 120px; }
        /* line 71, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
        #systems-tab-view .edit-system-container .nav-tabs li {
          z-index: 11; } }
  /* line 74, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view .edit-system-container .treema-root {
    position: absolute;
    top: 35px;
    right: 0;
    left: 0px;
    bottom: 0;
    overflow: scroll; }
  /* line 82, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
  #systems-tab-view .edit-system-container #create-new-system-button {
    position: absolute;
    top: 0;
    right: 0;
    left: auto; }
    @media screen and (max-width: 800px) {
      /* line 82, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
      #systems-tab-view .edit-system-container #create-new-system-button {
        left: 80px;
        top: 1px;
        bottom: auto;
        padding: 8px 10px; } }
    /* line 92, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view .edit-system-container #create-new-system-button .text {
      display: block; }
      @media screen and (max-width: 800px) {
        /* line 92, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
        #systems-tab-view .edit-system-container #create-new-system-button .text {
          display: none; } }
    /* line 96, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
    #systems-tab-view .edit-system-container #create-new-system-button [class^='icon-'] {
      display: none; }
      @media screen and (max-width: 800px) {
        /* line 96, /home/coco/codecombat/app/styles/editor/level/systems-tab-view.sass */
        #systems-tab-view .edit-system-container #create-new-system-button [class^='icon-'] {
          display: block; } }
