/* line 1, /home/coco/codecombat/app/styles/admin.sass */
#admin-view {
  color: black; }
  /* line 4, /home/coco/codecombat/app/styles/admin.sass */
  #admin-view #free-sub-input {
    min-width: 50%; }
  /* line 7, /home/coco/codecombat/app/styles/admin.sass */
  #admin-view .search-help {
    position: relative;
    display: none;
    width: 500px;
    border: 1px solid black;
    background-color: white;
    padding: 5px; }
