/* line 2, /home/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view h1, #progress-view h2, #progress-view h3 {
  margin-top: 0;
  color: black;
  margin-bottom: 5px; }

/* line 8, /home/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .next-level-description p {
  margin-top: 30px; }

/* line 11, /home/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .course-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

/* line 16, /home/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view #share-level-input {
  font-size: 12px;
  margin-top: 5px; }

/* line 20, /home/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view #share-level-btn {
  width: 100%; }
