/* line 2, /home/coco/codecombat/app/styles/modal/model-modal.sass */
#model-modal .treema-root {
  background-color: white; }

/* line 5, /home/coco/codecombat/app/styles/modal/model-modal.sass */
#model-modal .modal-dialog {
  width: 1000px; }

/* line 8, /home/coco/codecombat/app/styles/modal/model-modal.sass */
#model-modal .treema-ace .ace_editor {
  height: 600px; }
